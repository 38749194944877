import {
  Box,
  Button,
  Center,
  CheckboxGroup,
  Flex,
  Grid,
  Heading,
  Portal,
  RadioGroup,
  Spinner,
  Tag,
  Text,
  Textarea,
} from '@chakra-ui/react'
import { Global } from '@emotion/react'
import { CsbFormControl, CsbHeader, CsbPrimaryBox } from '@src/components'
import { CsbNewLineText } from '@src/components/CsbNewLineText'
import { CsbQuestionCheckbox } from '@src/components/CsbQuestionCheckbox'
import { CsbQuestionRadio } from '@src/components/CsbQuestionRadio'
import { useLearningThanksHooksResult } from '@src/components/pages/learing/[id]/thanks/hooks'
import {
  AnswerResearchInput,
  ResearchQuestionsQuery as ResearchQuestionsQueryType,
} from '@src/graphql/generated/graphql'
import { LearningResultQuery } from '@src/models/Learning'
import { LearningAnswerResult } from '@src/models/Learning/components/LearningAnswerResult'
import { ResearchAnswerResult } from '@src/models/Learning/components/ResearchAnswerResult'
import { memo, useCallback, useEffect, useState } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const Q = ({
  findError,
  form,
  parentIndex,
  researchQuestion,
}: {
  findError: (attributeName: string) => string
  form: UseFormReturn<
    Omit<AnswerResearchInput, 'clientMutationId' | 'shortPath'>
  >
  parentIndex: number
  researchQuestion: ResearchQuestionsQueryType['researchQuestions'][number]
}) => {
  const { t } = useTranslation()
  const isIncludeNameNewLine = researchQuestion.name.indexOf('\n') > -1

  return (
    <Box textAlign={'left'}>
      <Heading fontSize={16}>
        <CsbNewLineText text={researchQuestion.name} />
        {researchQuestion.isRequired ? (
          <Tag
            bgColor={'red.500'}
            color={'white'}
            fontWeight={'medium'}
            ml={!isIncludeNameNewLine ? 2 : 0}
          >
            {t('required')}
          </Tag>
        ) : (
          <></>
        )}
      </Heading>
      <CsbFormControl errorText={findError(`answers.${parentIndex}`)} mt={4}>
        {researchQuestion.answerType === 'radio' ? (
          <>
            <Controller
              control={form.control}
              name={`answers.${parentIndex}.0`}
              render={({ field }) => {
                return (
                  <RadioGroup
                    {...field}
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <Grid gap={1} gridColumn={'1fr'}>
                      {researchQuestion.answers.map((answer) => (
                        <CsbQuestionRadio
                          isChecked={field.value === answer}
                          key={answer}
                          value={answer}
                        >
                          {answer}
                        </CsbQuestionRadio>
                      ))}
                    </Grid>
                  </RadioGroup>
                )
              }}
            />
          </>
        ) : researchQuestion.answerType === 'checkbox' ? (
          <>
            <Controller
              control={form.control}
              name={`answers.${parentIndex}`}
              render={({ field }) => {
                return (
                  <CheckboxGroup {...field}>
                    <Grid gap={1} gridColumn={'1fr'}>
                      {researchQuestion.answers.map((answer, index) => (
                        <CsbQuestionCheckbox
                          isChecked={(() => {
                            const answers =
                              form.getValues(`answers.${parentIndex}`) ?? []
                            return answers.indexOf(answer) > -1
                          })()}
                          key={index}
                          value={answer}
                        >
                          {answer}
                        </CsbQuestionCheckbox>
                      ))}
                    </Grid>
                  </CheckboxGroup>
                )
              }}
            />
          </>
        ) : (
          <>
            <Controller
              control={form.control}
              name={`answers.${parentIndex}.0`}
              render={({ field }) => <Textarea {...field} />}
            />
          </>
        )}
      </CsbFormControl>
    </Box>
  )
}

//学習のサンクスページのcomponent
export const LearningThanks = memo(
  ({
    answerResearch,
    answerResearches,
    hasResearch,
    id,
    isHideAnswerResult,
    isResearchLoading,
    isSend,
    learning,
    learningResult,
    researchQuestions,
    setIsSend,
  }: useLearningThanksHooksResult) => {
    const { t } = useTranslation()
    const [isFocused, setIsFocused] = useState(false)

    // 最初のエラーのindexを取得
    const findErrorIndex = useCallback(() => {
      return (
        researchQuestions.data?.researchQuestions.findIndex((_, i) => {
          const message = answerResearch.findError(`answers.${i}`)
          const values = answerResearch.form.getValues(`answers.${i}`)
          return (
            message !== '' && (values?.filter((s) => !!s)?.length ?? 0) === 0
          )
        }) ?? -1
      )
    }, [answerResearch, researchQuestions.data?.researchQuestions])

    useEffect(() => {
      if (isFocused) {
        return
      }
      const errorIndex = findErrorIndex()
      if (errorIndex !== -1) {
        const element = document.getElementById(`question${errorIndex}`)
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' })
        }
        setIsFocused(true)
      }
    }, [isFocused, findErrorIndex])

    // アンケートが存在するか
    const existsResearch = useCallback(
      () => (researchQuestions.data?.researchQuestions.length ?? 0) > 0,
      [researchQuestions.data?.researchQuestions]
    )

    const existsLearning = useCallback(
      () =>
        !!learning.data?.learning?.movie?.uuid ||
        !!learning.data?.learning?.comic?.uuid ||
        !!learning.data?.learning?.learn?.uuid ||
        !!learning.data?.learning.pdf?.uuid ||
        !!learning.data?.learning?.phishingEmail?.originalLearningUrl,
      [learning.data?.learning]
    )

    useEffect(() => {
      if (hasResearch && !existsLearning()) {
        if (!isSend) {
          window.document.title = t('surveyMessage')
        } else {
          window.document.title = t('thanksToAnswer')
        }
      }
    }, [hasResearch, existsLearning, isSend, t])

    return (
      <>
        {learning.data?.learning.phishingEmail.isDisplayLogoInResearch && (
          <CsbHeader />
        )}
        <Flex
          alignItems="center"
          backgroundColor="gray.200"
          flexDirection="column"
          minHeight={
            learning.data?.learning.phishingEmail.isDisplayLogoInResearch
              ? 'calc(100vh - 80px)'
              : '100vh'
          }
          // justifyContent="center"
          p={20}
          width="100wh"
        >
          <Box
            backgroundColor={'white'}
            maxW={600}
            p={5}
            rounded={5}
            textAlign={'center'}
            w={'full'}
          >
            {/* 回答結果を非表示でなければ表示 */}
            {!isHideAnswerResult && (
              <>
                {existsLearning() ? (
                  <Heading p={5} size={'md'}>
                    {t('learn.thanksMessage')}
                  </Heading>
                ) : (
                  ''
                )}
                <LearningResultQuery {...learningResult}>
                  {({ answerResult }) => (
                    <Box mt={10}>
                      <Heading>{t('learn.results')}</Heading>
                      <Box
                        alignItems={'baseline'}
                        display={'flex'}
                        justifyContent={'center'}
                        mt={5}
                      >
                        <Text fontSize={'lg'}>
                          {answerResult.length ?? 0}
                          {t('learn.question')}
                        </Text>
                        <Text
                          color={'red.400'}
                          fontSize={'2xl'}
                          fontWeight={'bold'}
                        >
                          {answerResult.filter((n) => n.answer?.correct)
                            .length ?? 0}
                          {t('learn.correct')}
                        </Text>
                      </Box>
                    </Box>
                  )}
                </LearningResultQuery>
                <LearningAnswerResult {...learningResult} mb={12} mt={10} />
              </>
            )}

            {isResearchLoading ? (
              <Portal>
                <Global
                  styles={{
                    body: {
                      overflow: 'hidden',
                    },
                  }}
                />
                <Box
                  backdropFilter={'blur(3px)'}
                  bg={'blackAlpha.600'}
                  h={'full'}
                  inset={0}
                  pos={'fixed'}
                  w={'full'}
                >
                  <Center h={'full'}>
                    <Spinner
                      color="blue.500"
                      emptyColor="gray.200"
                      size="xl"
                      speed="0.65s"
                      thickness="4px"
                    />
                  </Center>
                </Box>
              </Portal>
            ) : (
              <>
                {hasResearch ? (
                  <>
                    {!isSend ? (
                      <>
                        <Text>{t('surveyMessage')}</Text>
                        <CsbPrimaryBox
                          display={'flex'}
                          flexDirection={'column'}
                          gap={10}
                          mt={5}
                          px={5}
                          py={6}
                        >
                          {researchQuestions.data?.researchQuestions.map(
                            (researchQuestion, index) => {
                              return (
                                <div id={`question${index}`} key={index}>
                                  <Q
                                    findError={answerResearch.findError}
                                    form={answerResearch.form}
                                    key={researchQuestion.uuid}
                                    parentIndex={index}
                                    researchQuestion={researchQuestion}
                                  />
                                </div>
                              )
                            }
                          )}
                          <Button
                            isDisabled={isSend}
                            mt={10}
                            onClick={() => {
                              setIsFocused(false)
                              const finish = () => {
                                answerResearches.refetch().then()
                                setIsSend()
                              }

                              if (existsResearch()) {
                                // アンケートが存在する場合
                                answerResearch.onAnswerResearch(id, finish)()
                              }
                            }}
                          >
                            {t('submit')}
                          </Button>
                        </CsbPrimaryBox>
                      </>
                    ) : (
                      <Box px={'15px'}>
                        <Box pt={8}>{t('thanksToAnswer')}</Box>

                        {existsResearch() && (
                          <Box mt={5}>
                            <Text
                              align={'left'}
                              color={'teal'}
                              fontSize={14}
                              fontWeight={'bold'}
                            >
                              {t('research.result.contentTitle')}
                            </Text>
                            <ResearchAnswerResult
                              answerResearches={
                                answerResearches.data?.answerResearches ?? []
                              }
                              researchQuestions={
                                researchQuestions.data?.researchQuestions ?? []
                              }
                            />
                          </Box>
                        )}
                        <Box pb={5} pt={5}>
                          {t('closeWindow')}
                        </Box>
                      </Box>
                    )}
                  </>
                ) : (
                  <Box pb={5} pt={8}>
                    {t('closeWindow')}
                  </Box>
                )}
              </>
            )}
          </Box>
        </Flex>
      </>
    )
  }
)
