import { useToast } from '@chakra-ui/react'
import { paginationInput } from '@src/constants'
import {
  CreatePhishingSiteInput,
  UpdatePhishingSiteInput,
  useCreatePhishingSiteMutation,
  useDestroyPhishingSiteMutation,
  usePhishingSitesQuery,
  useUpdatePhishingSiteCompaniesMutation,
  useUpdatePhishingSiteMutation,
} from '@src/graphql/generated/graphql'
import { useDeleteModal, useFormModal, useMe, usePagination } from '@src/hooks'
import { useCsbDataModal } from '@src/hooks/useCsbDataModal'
import { useForm } from 'react-hook-form'
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'

export const useSitesHooks = () => {
  const { role } = useMe()
  const isWritePhishingSite = !!role?.writePhishingSite
  const deleteModal = useDeleteModal()
  const [destroyPhishingSiteMutation] = useDestroyPhishingSiteMutation()
  const [createPhishingSiteMutation, { loading: isCreating }] =
    useCreatePhishingSiteMutation()
  const [updatePhishingSiteMutation, { loading: isUpdating }] =
    useUpdatePhishingSiteMutation()
  const formModal = useFormModal<
    CreatePhishingSiteInput | Omit<UpdatePhishingSiteInput, 'uuid'>
  >({
    defaultValues: {
      locked: false,
      name: '',
    },
  })

  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    searchWord: withDefault(StringParam, ''),
  })
  //ページネーション
  const { current, onChangePage } = usePagination()

  const { data, refetch } = usePhishingSitesQuery({
    variables: {
      pagination: paginationInput(current),
      searchWord: query.searchWord,
    },
  })

  //編集するModalを開くボタン
  const onUpdate = ({
    description,
    locked,
    name,
    url,
    uuid,
  }: UpdatePhishingSiteInput) => {
    formModal.onOpen()
    formModal.form.setValue('name', name)
    formModal.form.setValue('url', url)
    formModal.form.setValue('description', description)
    formModal.form.setValue('locked', locked)
    formModal.setUuid(uuid)
    formModal.setState('update')
  }

  //新規作成、編集のModalのformを送信する関数
  const onSubmit = async (
    inputData: CreatePhishingSiteInput | Omit<UpdatePhishingSiteInput, 'uuid'>
  ) => {
    try {
      //typeを判定
      switch (formModal.state) {
        case 'create':
          //createの場合はcreateGroupにinputDataを渡す
          await createPhishingSiteMutation({
            variables: {
              input: inputData,
            },
          })
          break
        case 'update':
          //createの場合はupdateGroupMutationにinputDataとuuidを渡す
          await updatePhishingSiteMutation({
            variables: {
              input: {
                ...inputData,
                uuid: formModal.uuid,
              },
            },
          })
          break
        //一応エラーを書く
        default:
          new Error(`${formModal.state} does not exist`)
      }

      //新規作成、編集をすればToastを発行する
      formModal.toast({
        status: 'success',
        title: formModal.isCreate
          ? `${inputData.name || 'フィッシングサイト'}を追加しました。`
          : `${inputData.name || 'フィッシングサイト'}を編集しました。`,
      })
      formModal.setErrors([])
      formModal.onCancel()
      /**
       * TODO anyを消す
       * e instanceOf ApolloError
       * setErrorsを変更する
       */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      formModal.setErrors(e.graphQLErrors)
      //新規作成、編集が失敗した場合のToast
      formModal.toast({
        status: 'error',
        title: formModal.isCreate
          ? `${inputData.name || 'フィッシングサイト'}の追加に失敗しました。`
          : `${inputData.name || 'フィッシングサイト'}の編集に失敗しました。`,
      })
    }
    await refetch()
  }

  const dataModal = useCsbDataModal<{
    items: { id: number; name: string; uuid: string }[]
    title: string
  }>()

  //search機能
  const searchForm = useForm<{ searchWord: string }>({
    defaultValues: {
      searchWord: query.searchWord,
    },
  })
  const onSearch = searchForm.handleSubmit(async (inputData) => {
    setQuery({
      page: 1,
      searchWord: inputData.searchWord,
    })
  })
  const onResetSearch = () => {
    searchForm.setValue('searchWord', '')

    setQuery({
      page: 1,
      searchWord: '',
    })
  }

  const [
    updatePhishingSiteCompaniesMutation,
    { loading: isUpdatingPhishingSiteCompanies },
  ] = useUpdatePhishingSiteCompaniesMutation()

  const toast = useToast()

  const onUpdateSelectCompanies = async (
    uuid: string,
    companyUuids: string[]
  ) => {
    try {
      await updatePhishingSiteCompaniesMutation({
        variables: {
          input: {
            companies: companyUuids,
            uuid: uuid,
          },
        },
      })
      toast({
        status: 'success',
        title: 'フィッシングサイトの対象企業を設定しました。',
      })
    } catch (e) {
      toast({
        status: 'error',
        title: 'フィッシングサイトの対象企業の設定に失敗しました。',
      })
    }
    await refetch()
  }
  return {
    current,
    data,
    dataModal,
    deleteModal,
    destroyPhishingSiteMutation,
    formModal,
    isCreating,
    isUpdating,
    isUpdatingPhishingSiteCompanies,
    isWritePhishingSite,
    onChangePage,
    onResetSearch,
    onSearch,
    onSubmit,
    onUpdate,
    onUpdateSelectCompanies,
    refetch,
    searchForm,
  }
}

export type useSitesHooksResult = ReturnType<typeof useSitesHooks>
