import { useDisclosure, useToast } from '@chakra-ui/react'
import { useTemplateModal } from '@src/components/Modal/CsbTemplateModal/hooks'
import { usePhishingEmailGroups } from '@src/components/pages/phishing/hooks/usePhishingEmailGroups'
import {
  UpdatePhishingEmailInput,
  UpdatePhishingEmailMutation,
  usePhishingEmailQuery,
  useUpdatePhishingEmailMutation,
} from '@src/graphql/generated/graphql'
import { useErrors, useMe, usePagination } from '@src/hooks'
import { useDeliveryContents } from '@src/hooks/useDeliveryContents'
import {
  isAppMessageEvent,
  useWindowMessageSender,
} from '@src/hooks/useWindowMessage'
import { phishingFormDefaultValues } from '@src/pages/phishing/formDefaultValues'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

//フィッシング編集で使うhooksのまとめ
export const usePhishingEditHooks = () => {
  const { isDeliveryContentsOnly, resolveDeliveryContentsUrl } =
    useDeliveryContents()
  //保存と保存して送信のボタンのloadingアニメーションを分岐するstate
  const [isDraftButton, setIsDraftButton] = useState(true)
  //保存して送信するModalを管理するstate
  const { isOpen, onClose, onOpen } = useDisclosure()
  const navigate = useNavigate()
  const { onBackListPage } = usePagination()
  const toast = useToast()
  const { id } = useParams<{ id: string }>()

  const [errors, setErrors] = useErrors()
  const form = useForm<Omit<UpdatePhishingEmailInput, 'uuid'>>({
    defaultValues: phishingFormDefaultValues,
  })
  const templateModal = useTemplateModal(form)
  const { role } = useMe()
  const isWritePhishingMail = !!role?.writePhishingMail

  //アップデート後に返却されるuserを管理するstate
  const [userData, setUserData] = useState<
    | NonNullable<
        UpdatePhishingEmailMutation['updatePhishingEmail']
      >['phishingEmail']
    | undefined
  >(undefined)
  const [uuid, setUuid] = useState('')

  const { groups, isGroupsDataLoading, updatePhishingEmailGroups } =
    usePhishingEmailGroups(uuid)

  //フィッシングをアップデートする関数
  const [updatePhishingEmailMutation, { loading: isUpdating }] =
    useUpdatePhishingEmailMutation({
      onCompleted: ({ updatePhishingEmail }) => {
        setUserData(updatePhishingEmail?.phishingEmail)
        setUuid(updatePhishingEmail?.phishingEmail.uuid ?? '')
        updatePhishingEmailGroups()
      },
    })

  const [isCompletedData, setIsCompleted] = useState(false)

  //現在のページのフィッシングのデータ
  const { data, loading } = usePhishingEmailQuery({
    onCompleted: async ({ phishingEmail }) => {
      form.setValue('attachId', phishingEmail.attach?.uuid)
      form.setValue('companyAttachId', phishingEmail.companyAttach?.uuid)
      form.setValue(
        'isPhishingSiteDirectAccess',
        !!phishingEmail.isPhishingSiteDirectAccess
      )
      form.setValue('isSendAllUsers', !!phishingEmail.isSendAllUsers)
      form.setValue('reserveAt', phishingEmail.reserveAt)
      form.setValue('name', phishingEmail.name)
      form.setValue('subject', phishingEmail.subject)
      form.setValue('body', phishingEmail.body)
      form.setValue('isBodyLock', !!phishingEmail.isBodyLock)
      form.setValue('fromName', phishingEmail.fromName)
      form.setValue('mailFromId', phishingEmail.mailFrom.uuid)
      form.setValue('isSendLearningMail', !!phishingEmail.isSendLearningMail)
      form.setValue(
        'organizationIds',
        phishingEmail.organizations.map(({ uuid }) => uuid)
      )
      form.setValue(
        'groupIds',
        phishingEmail.groups.map(({ uuid }) => uuid)
      )
      form.setValue(
        'positionIds',
        phishingEmail.positions.map(({ uuid }) => uuid)
      )
      form.setValue('learnId', phishingEmail.learn?.uuid)
      form.setValue('movieId', phishingEmail.movie?.uuid)
      form.setValue('comicId', phishingEmail.comic?.uuid)
      form.setValue('pdfId', phishingEmail.pdf?.uuid)
      form.setValue('phishingLinkLabel', phishingEmail.phishingLinkLabel)
      form.setValue(
        'revealingPhishingEmailSubject',
        phishingEmail.revealingPhishingEmailSubject
      )
      form.setValue(
        'revealingPhishingEmailHtml',
        phishingEmail.revealingPhishingEmailHtml
      )
      form.setValue(
        'isRevealingPhishingEmailHtmlLock',
        !!phishingEmail.isRevealingPhishingEmailHtmlLock
      )
      form.setValue(
        'revealingPhishingEmailFromName',
        phishingEmail.revealingPhishingEmailFromName
      )
      form.setValue(
        'revealingPhishingEmailFromEmail',
        phishingEmail.revealingPhishingEmailFromEmail
      )
      form.setValue(
        'revealingPhishingEmailMailFromId',
        phishingEmail.revealingPhishingEmailMailFrom?.uuid
      )
      form.setValue(
        'phishingResultLayoutTemplate',
        phishingEmail.phishingResultLayoutTemplate
      )
      form.setValue('phishingResultTitle', phishingEmail.phishingResultTitle)
      form.setValue('phishingResultBody', phishingEmail.phishingResultBody)
      form.setValue(
        'isPhishingResultBodyLock',
        !!phishingEmail.isPhishingResultBodyLock
      )
      form.setValue('phishingResultSign', phishingEmail.phishingResultSign)
      form.setValue(
        'isPhishingResultSignLock',
        !!phishingEmail.isPhishingResultSignLock
      )
      form.setValue('riskUserEmailSubject', phishingEmail.riskUserEmailSubject)
      form.setValue('riskUserEmailHtml', phishingEmail.riskUserEmailHtml)
      form.setValue(
        'isRiskUserEmailHtmlLock',
        !!phishingEmail.isRiskUserEmailHtmlLock
      )
      form.setValue('phishingSiteId', phishingEmail.phishingSite?.uuid)
      form.setValue('rating', Number(phishingEmail.rating))
      form.setValue('pdfLink', phishingEmail.pdfLink)
      form.setValue('linkText', phishingEmail.linkText)
      form.setValue('researchId', phishingEmail.research?.uuid)
      form.setValue(
        'isDeliveryContentsOnly',
        !!phishingEmail.isPhishingSiteDirectAccess
      )
      form.setValue('attachName', phishingEmail.attachName ?? '')
      form.setValue(
        'originalLearningUrl',
        phishingEmail.originalLearningUrl ?? ''
      )
      form.setValue('sendBlockInterval', phishingEmail.sendBlockInterval)
      form.setValue('sendBlockSize', phishingEmail.sendBlockSize)
      form.setValue('sendEmailPattern', phishingEmail.sendEmailPattern ?? 1)
      form.setValue(
        'isDisplayLogoInLearning',
        phishingEmail.isDisplayLogoInLearning
      )
      form.setValue(
        'isDisplayLogoInResearch',
        phishingEmail.isDisplayLogoInResearch
      )
      form.setValue('isTrackMailOpen', phishingEmail.isTrackMailOpen)
      form.setValue(
        'learningContentComment',
        phishingEmail.learningContentComment
      )
      form.setValue(
        'isLearningContentCommentLock',
        !!phishingEmail.isLearningContentCommentLock
      )
      form.setValue('learningComment', phishingEmail.learningComment)

      form.setValue(
        'isLearningCommentLock',
        !!phishingEmail.isLearningCommentLock
      )
      form.setValue('isRiskReplyUser', phishingEmail.isRiskReplyUser)
      form.setValue('isFromAsReply', phishingEmail.isFromAsReply)
      form.setValue('isArchiveAttach', phishingEmail.isArchiveAttach)
      form.setValue('archiveAttachName', phishingEmail.archiveAttachName)
      form.setValue(
        'isArchiveAttachPassword',
        phishingEmail.isArchiveAttachPassword
      )
      form.setValue(
        'isCustomNotifyPassword',
        phishingEmail.isCustomNotifyPassword
      )
      form.setValue(
        'archiveAttachPassword',
        phishingEmail.archiveAttachPassword
      )
      form.setValue(
        'notifyArchivePasswordSubject',
        phishingEmail.notifyArchivePasswordSubject
      )
      form.setValue(
        'notifyArchivePasswordHtml',
        phishingEmail.notifyArchivePasswordHtml
      )
      form.setValue(
        'isNotifyArchivePasswordHtmlLock',
        !!phishingEmail.isNotifyArchivePasswordHtmlLock
      )

      form.setValue('isTest', phishingEmail.isTest)
      form.setValue('remindEmailSubject', phishingEmail.remindEmailSubject)
      form.setValue('remindEmailHtml', phishingEmail.remindEmailHtml)
      form.setValue(
        'isRemindEmailHtmlLock',
        !!phishingEmail.isRemindEmailHtmlLock
      )
      setIsCompleted(true)
    },
    onError: () => {
      setIsCompleted(true)
    },
    variables: { isDeliveryContentsOnly, uuid: id ?? '' },
  })

  const { sendMessage } = useWindowMessageSender()

  /**
   * 送信確認のアクション
   */
  const onSubmit = (
    type: 'draft' | 'send' | 'preview_phishing_result' | 'preview_learning'
  ) =>
    form.handleSubmit(
      async ({
        attachId,
        attachName,
        companyAttachId,
        pdfId,
        researchId,
        sendBlockInterval,
        sendEmailPattern,
        ...inputData
      }) => {
        //保存ボタンか保存して送信するボタンかどうか
        setIsDraftButton(type !== 'send')
        try {
          const { data } = await updatePhishingEmailMutation({
            variables: {
              input: {
                ...inputData,
                attachId: attachId === '' ? null : attachId,
                attachName: attachName === '' ? null : attachName,
                companyAttachId:
                  companyAttachId === '' ? null : companyAttachId,
                draft: true,
                isDeliveryContentsOnly,
                pdfId: pdfId === '' ? null : pdfId,
                researchId: researchId === '' ? null : researchId,
                sendBlockInterval: !sendBlockInterval
                  ? null
                  : Number(sendBlockInterval),
                sendEmailPattern: Number(sendEmailPattern) || 1,
                uuid: id ?? '',
              },
            },
          })
          setErrors([])
          //保存ボタンの場合のみToastを発行しページ遷移する
          if (type === 'draft') {
            toast({
              duration: 3000,
              isClosable: true,
              position: 'top',
              status: 'success',
              title: 'メールを編集しました。',
            })
            navigate(resolveDeliveryContentsUrl('/phishing'))
          }

          if (type === 'send') {
            onOpen()
          }

          if (type === 'preview_phishing_result') {
            const newTab = window.open(
              process.env.REACT_APP_PHISHING_URL + '/preview/phishing-result',
              'preview'
            )

            if (!newTab) return

            const listener = (event: MessageEvent) => {
              if (!isAppMessageEvent(event)) {
                return
              }
              if (event.data.payload.message === 'preview-loaded') {
                // プレビュー画面が表示されたら、プレビュー画面にデータを送信する
                sendMessage(newTab, {
                  payload: {
                    phishingEmail:
                      data?.updatePhishingEmail?.previewPhishingResult,
                  },
                })
                // リスナーを削除
                window.removeEventListener('message', listener)
              }
            }

            // 子ウィンドウからのメッセージを受け取る
            window.addEventListener('message', listener)
          }

          if (type === 'preview_learning') {
            const uuid = data?.updatePhishingEmail?.phishingEmail.uuid ?? ''
            window.open(
              `/preview/learning/${uuid}?isDeliveryContentsOnly=${isDeliveryContentsOnly}`,
              '_blank'
            )
          }

          /**
           * TODO anyを消す
           * e instanceOf ApolloError
           * setErrorsを変更する
           */
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          toast({
            duration: 3000,
            isClosable: true,
            position: 'top',
            status: 'error',
            title: 'メールの編集に失敗しました。',
          })
          setErrors(e.graphQLErrors)
        }
      }
    )

  return {
    data,
    errors,
    form,
    groups,
    isCompletedData,
    isDeliveryContentsOnly,
    isDraftButton,
    isGroupsDataLoading,
    isOpen,
    isUpdating,
    isWritePhishingMail,
    loading,
    navigate,
    onBackListPage,
    onClose,
    onSubmit,
    resolveDeliveryContentsUrl,
    role,
    templateModal,
    userData,
    uuid,
  }
}

export type usePhishingEditHooksResult = ReturnType<typeof usePhishingEditHooks>
