import { useToast } from '@chakra-ui/react'
import {
  PhishingResultLayoutTemplate,
  UpdateSettingInput,
  useSettingsQuery,
  useUpdateSettingMutation,
} from '@src/graphql/generated/graphql'
import { useErrors, useMe } from '@src/hooks'
import dayjs from 'dayjs'
import { useState } from 'react'
import { useForm } from 'react-hook-form'

//ネタバレメール設定ページで使うhooksのまとめ
export const useSettingHooks = () => {
  const { role } = useMe()
  const isWriteConfig = !!role?.writeConfig
  const isWriteIpFilter = !!role?.writeIpFilter
  const form = useForm<UpdateSettingInput>({
    defaultValues: {
      clientMutationId: null,
      isArchiveAttach: false,
      isArchiveAttachPassword: false,
      isCustomNotifyPassword: false,
      isDisplayLogoInLearning: true,
      isDisplayLogoInResearch: true,
      isRiskReplyUser: false,
      learningAccessedAllowCount: 0,
      linkText: '',
      logoPath: '',
      mailOpenedAllowCount: 0,
      phishingResultBody: '',
      phishingResultLayoutTemplate: PhishingResultLayoutTemplate.Default,
      phishingResultSign: '',
      phishingResultTitle: '',
      proxyApiHost: '',
      proxyPhishingHost: '',
      remindEmailHtml: '',
      remindEmailSubject: '',
      revealingPhishingEmailHtml: '',
      revealingPhishingEmailSubject: '',
      riskUserEmailHtml: '',
      riskUserEmailSubject: '',
      sendEmailPattern: 1,
      sendLearningMailAt: '15:00',
      sendLearningMailDays: 5,
      sendMailInterval: 1.0,
      useLogo: false,
      webAccessedAllowCount: 0,
      webLoginAllowCount: 0,
    },
  })
  const toast = useToast()
  const [errors, setErrors] = useErrors()
  const [updateSettingMutation] = useUpdateSettingMutation()
  const [isLoadedSettingData, setIsLoadedSettingData] = useState(false)

  const { data, loading, refetch } = useSettingsQuery({
    onCompleted: ({ ipFilters, setting }) => {
      form.setValue(
        'sendLearningMailDays',
        Number(setting.sendLearningMailDays ?? 5)
      )
      form.setValue(
        'sendLearningMailAt',
        dayjs(setting.sendLearningMailAt).format('HH:mm') ?? '15:00'
      )
      form.setValue(
        'revealingPhishingEmailHtml',
        setting.revealingPhishingEmailHtml
      )
      form.setValue(
        'isRevealingPhishingEmailHtmlLock',
        setting.isRevealingPhishingEmailHtmlLock
      )
      form.setValue(
        'revealingPhishingEmailSubject',
        setting.revealingPhishingEmailSubject
      )
      form.setValue('riskUserEmailHtml', setting.riskUserEmailHtml)
      form.setValue(
        'isRiskUserEmailHtmlLock',
        setting.isRevealingPhishingEmailHtmlLock
      )
      form.setValue('riskUserEmailSubject', setting.riskUserEmailSubject)
      form.setValue('phishingResultBody', setting.phishingResultBody)
      form.setValue(
        'isPhishingResultBodyLock',
        setting.isRevealingPhishingEmailHtmlLock
      )
      form.setValue(
        'phishingResultLayoutTemplate',
        setting.phishingResultLayoutTemplate
      )
      form.setValue('phishingResultTitle', setting.phishingResultTitle)
      form.setValue('phishingResultSign', setting.phishingResultSign)
      form.setValue(
        'isPhishingResultSignLock',
        setting.isPhishingResultSignLock
      )
      form.setValue('sendEmailPattern', setting.sendEmailPattern)
      form.setValue('logoPath', setting.logoPath)
      form.setValue('useLogo', setting.useLogo)
      form.setValue(
        'isPhishingSiteDirectAccess',
        setting.isPhishingSiteDirectAccess
      )
      form.setValue('linkText', setting.linkText)
      form.setValue(
        'mailOpenedAllowCount',
        Number(setting.mailOpenedAllowCount ?? 0)
      )
      form.setValue(
        'webAccessedAllowCount',
        Number(setting.webAccessedAllowCount ?? 0)
      )
      form.setValue(
        'webLoginAllowCount',
        Number(setting.webLoginAllowCount ?? 0)
      )
      form.setValue(
        'learningAccessedAllowCount',
        Number(setting.learningAccessedAllowCount ?? 0)
      )
      form.setValue('sendMailInterval', setting.sendMailInterval ?? 1.0)
      form.setValue('isDisplayLogoInLearning', setting.isDisplayLogoInLearning)
      form.setValue('isDisplayLogoInResearch', setting.isDisplayLogoInResearch)
      form.setValue('isTrackMailOpen', setting.isTrackMailOpen)
      form.setValue(
        'learningContentComment',
        setting.learningContentComment ?? ''
      )
      form.setValue(
        'isLearningContentCommentLock',
        setting.isLearningContentCommentLock
      )
      form.setValue('learningComment', setting.learningComment ?? '')
      form.setValue('isLearningCommentLock', setting.isLearningCommentLock)
      // noinspection DuplicatedCode
      form.setValue('isRiskReplyUser', setting.isRiskReplyUser)
      form.setValue('isArchiveAttach', setting.isArchiveAttach)
      form.setValue('isArchiveAttachPassword', setting.isArchiveAttachPassword)
      form.setValue('archiveAttachPassword', setting.archiveAttachPassword)
      form.setValue('isCustomNotifyPassword', setting.isCustomNotifyPassword)
      form.setValue(
        'notifyArchivePasswordSubject',
        setting.notifyArchivePasswordSubject
      )
      form.setValue(
        'notifyArchivePasswordHtml',
        setting.notifyArchivePasswordHtml
      )
      form.setValue(
        'isNotifyArchivePasswordHtmlLock',
        setting.isNotifyArchivePasswordHtmlLock
      )
      form.setValue('isSendLearningMail', setting.isSendLearningMail)
      form.setValue('remindEmailSubject', setting.remindEmailSubject)
      form.setValue('remindEmailHtml', setting.remindEmailHtml)

      form.setValue('proxyApiHost', setting.proxyApiHost)
      form.setValue('proxyPhishingHost', setting.proxyPhishingHost)

      const filters = ipFilters.map((ipFilter) => ({
        id: ipFilter.id ? ipFilter.id : null,
        ipAddress: ipFilter.ipAddress,
        userAgent: ipFilter.userAgent,
      }))

      form.setValue(
        'ipFilters',
        filters.length === 0
          ? [{ id: null, ipAddress: '', userAgent: '' }]
          : filters
      )
      setIsLoadedSettingData(true)
    },
  })

  const isDefaultPhishingResultLayout =
    form.watch('phishingResultLayoutTemplate') ===
    PhishingResultLayoutTemplate.Default

  const onSubmit = form.handleSubmit(
    async ({
      archiveAttachPassword,
      clientMutationId,
      ipFilters,
      isArchiveAttach,
      isArchiveAttachPassword,
      isCustomNotifyPassword,
      isDisplayLogoInLearning,
      isDisplayLogoInResearch,
      isLearningCommentLock,
      isLearningContentCommentLock,
      isNotifyArchivePasswordHtmlLock,
      isPhishingResultBodyLock,
      isPhishingResultSignLock,
      isPhishingSiteDirectAccess,
      isRevealingPhishingEmailHtmlLock,
      isRiskReplyUser,
      isRiskUserEmailHtmlLock,
      isSendLearningMail,
      isTrackMailOpen,
      learningAccessedAllowCount,
      learningComment,
      learningContentComment,
      linkText,
      logoPath,
      mailOpenedAllowCount,
      notifyArchivePasswordHtml,
      notifyArchivePasswordSubject,
      phishingResultBody,
      phishingResultLayoutTemplate,
      phishingResultSign,
      phishingResultTitle,
      proxyApiHost,
      proxyPhishingHost,
      remindEmailHtml,
      remindEmailSubject,
      revealingPhishingEmailHtml,
      revealingPhishingEmailSubject,
      riskUserEmailHtml,
      riskUserEmailSubject,
      sendEmailPattern,
      sendLearningMailAt,
      sendLearningMailDays,
      sendMailInterval,
      useLogo,
      webAccessedAllowCount,
      webLoginAllowCount,
    }: UpdateSettingInput) => {
      const [hour, minute] = sendLearningMailAt.split(':')
      const validIpFilters = (ipFilters ?? []).filter(
        (ipFilter) => ipFilter.ipAddress !== '' || ipFilter.userAgent !== ''
      )

      try {
        await updateSettingMutation({
          variables: {
            input: {
              archiveAttachPassword,
              clientMutationId,
              ipFilters: validIpFilters,
              isArchiveAttach,
              isArchiveAttachPassword,
              isCustomNotifyPassword,
              isDisplayLogoInLearning,
              isDisplayLogoInResearch,
              isLearningCommentLock,
              isLearningContentCommentLock,
              isNotifyArchivePasswordHtmlLock,
              isPhishingResultBodyLock,
              isPhishingResultSignLock,
              isPhishingSiteDirectAccess,
              isRevealingPhishingEmailHtmlLock,
              isRiskReplyUser,
              isRiskUserEmailHtmlLock,
              isSendLearningMail,
              isTrackMailOpen,
              learningAccessedAllowCount: Number(learningAccessedAllowCount),
              learningComment,
              learningContentComment,
              linkText,
              logoPath,
              mailOpenedAllowCount: Number(mailOpenedAllowCount),
              notifyArchivePasswordHtml,
              notifyArchivePasswordSubject,
              phishingResultBody,
              phishingResultLayoutTemplate,
              phishingResultSign,
              phishingResultTitle,
              proxyApiHost,
              proxyPhishingHost,
              remindEmailHtml,
              remindEmailSubject,
              revealingPhishingEmailHtml,
              revealingPhishingEmailSubject,
              riskUserEmailHtml,
              riskUserEmailSubject,
              sendEmailPattern: Number(sendEmailPattern),
              sendLearningMailAt: dayjs().hour(hour).minute(minute).format(),
              sendLearningMailDays: Number(sendLearningMailDays),
              sendMailInterval: Number(sendMailInterval),
              useLogo,
              webAccessedAllowCount: Number(webAccessedAllowCount),
              webLoginAllowCount: Number(webLoginAllowCount),
            },
          },
        })

        setErrors([])
        setIsLoadedSettingData(false)
        toast({
          duration: 3000,
          isClosable: true,
          position: 'top',
          status: 'success',
          title: `設定を更新しました。`,
        })
        await refetch()
        /**
         * TODO anyを消す
         * e instanceOf ApolloError
         * setErrorsを変更する
         */
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        setErrors(e.graphQLErrors)
        toast({
          duration: 3000,
          isClosable: true,
          position: 'top',
          status: 'error',
          title: `設定更新に失敗しました。`,
        })
      }
    }
  )

  return {
    data,
    errors,
    form,
    isDefaultPhishingResultLayout,
    isLoadedSettingData,
    isWriteConfig,
    isWriteIpFilter,
    loading,
    onSubmit,
  }
}

export type useSettingHooksResult = ReturnType<typeof useSettingHooks>
