import { QuestionOutlineIcon } from '@chakra-ui/icons'
import { Tooltip, TooltipProps } from '@chakra-ui/react'
import { memo, ReactChild } from 'react'

type CsbHintProps = {
  children: ReactChild
} & Omit<TooltipProps, 'children' | 'label'>

export const CsbHint = memo(({ children }: CsbHintProps) => {
  return (
    <Tooltip hasArrow label={children} placement={'bottom-start'}>
      <QuestionOutlineIcon color={'teal.500'} />
    </Tooltip>
  )
})
