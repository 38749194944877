import { QuestionOutlineIcon } from '@chakra-ui/icons'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertIcon,
  AspectRatio,
  Box,
  Button,
  chakra,
  Checkbox,
  Divider,
  Flex,
  Grid,
  Heading,
  HStack,
  Image,
  Input,
  ModalCloseButton,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Spinner,
  Switch,
  Text,
  Textarea,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import {
  CsbDeleteButton,
  CsbEditor,
  CsbFormControl,
  CsbList,
  CsbListItem,
  CsbModal,
  CsbModalBody,
  CsbModalFooter,
  CsbModalHeader,
  CsbMultiSelect,
} from '@src/components'
import { CsbHint } from '@src/components/CsbHint/CsbHint'
import {
  createMailFromOptions,
  CsbMailFromSelect,
  getMalFromEmail,
  MailFromOption,
} from '@src/components/CsbMailFromSelect/CsbMailFromSelect'
import { AttachFileForm } from '@src/components/Form/AttachFileForm'
import { RevealingPhishingForm } from '@src/components/Form/RevealingPhishingForm'
import { CsbDataModal } from '@src/components/Modal/CsbDataModal'
import {
  getMailPlaceholders,
  useMailPlaceholderModal,
} from '@src/components/Modal/MailPlaceholderModal/MailPlaceholderModal'
import { PhishingResultPagePlaceholderModal } from '@src/components/Modal/PhishingResultPagePlaceholderModal'
import { REVEALING_MAIL_PLACEHOLDERS } from '@src/constants/mailPlaceholder'
import { BaseResponseError } from '@src/errors/BaseResponseError'
import {
  ComicsWithPhishingEditFormQuery,
  CreatePhishingEmailInput,
  Group,
  LearnsWithPhishingEditFormQuery,
  MoviesWithPhishingEditFormQuery,
  Organization,
  PhishingEmailQuery,
  PhishingResultLayoutTemplate,
  Position,
  ResearchesWithPhishingEditFormQuery,
  UpdatePhishingEmailInput,
  useComicsWithPhishingEditFormLazyQuery,
  useComicWithPhishingEditFormQuery,
  useLearnsWithPhishingEditFormLazyQuery,
  useLearnWithPhishingEditFormQuery,
  useMoviesWithPhishingEditFormLazyQuery,
  useMovieWithPhishingEditFormQuery,
  usePhishingEditFormQuery,
  useResearchesWithPhishingEditFormLazyQuery,
  useResearchWithPhishingEditFormQuery,
} from '@src/graphql/generated/graphql'
import { useMe, useMultiSelect } from '@src/hooks'
import { useCsbDataModal } from '@src/hooks/useCsbDataModal'
import { useDeliveryContents } from '@src/hooks/useDeliveryContents'
import { findError } from '@src/utils/findError'
import Vimeo from '@u-wave/react-vimeo'
import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
import { Controller } from 'react-hook-form'
import { UseFormReturn } from 'react-hook-form/dist/types'
import { FaStar } from 'react-icons/fa'
import Rating from 'react-rating'

import { PhishingEmailSchedules } from '../pages/phishing/[id]/PhishingEmailSchedules'

type PhishingEmailSchedulesArray =
  PhishingEmailQuery['phishingEmail']['phishingEmailSchedules']

type PhishingEditFormPropsType = {
  errors: [BaseResponseError?]
  //TODO anyを消す
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<CreatePhishingEmailInput | UpdatePhishingEmailInput | any>
  isLock?: boolean
  isNew?: boolean
  schedules?: PhishingEmailSchedulesArray
}

type MoviesArray = MoviesWithPhishingEditFormQuery['movies']['collection']
type ComicsArray = ComicsWithPhishingEditFormQuery['comics']['collection']
type LearnsArray = LearnsWithPhishingEditFormQuery['learns']['collection']
type ResearchesArray =
  ResearchesWithPhishingEditFormQuery['researches']['collection']

// 送信メールパターン
const SendEmailPattern = {
  Html: 1,
  Text: 2,
}

export const PhishingEditForm = ({
  errors,
  form,
  isLock = false,
  isNew = false,
  schedules = undefined,
}: PhishingEditFormPropsType) => {
  const { company, role } = useMe()
  const isWritePhishingMail = !!role?.writePhishingMail
  const { isDeliveryContentsOnly } = useDeliveryContents()
  const [MailRevealingModal, { onOpen: mailRevealingModalOnOpen }] =
    useMailPlaceholderModal(REVEALING_MAIL_PLACEHOLDERS)

  // コンテンツ配信の場合は、ラーニングページへのリンクと説明を変更する
  const mailPlaceHolders = getMailPlaceholders(isDeliveryContentsOnly)

  const [MailPlaceholderModal, { onOpen: mailPlaceholderModalOnOpen }] =
    useMailPlaceholderModal(mailPlaceHolders)
  const { isOpen, onClose, onOpen } = useDisclosure()

  const { data, loading } = usePhishingEditFormQuery({
    onCompleted: ({ mailFroms, phishingSites, setting }) => {
      if (!form.getValues('mailFromId')) {
        form.setValue('mailFromId', mailFroms.collection[0].uuid)
      }

      if (!form.getValues('linkText')) {
        form.setValue('linkText', setting.linkText)
      }

      // nullだと501になるのでnullの場合は代入
      if (!form.getValues('phishingSiteId')) {
        form.setValue('phishingSiteId', phishingSites.collection[0].uuid)
      }

      if (!form.getValues('revealingPhishingEmailSubject')) {
        form.setValue(
          'revealingPhishingEmailSubject',
          setting.revealingPhishingEmailSubject
        )
      }

      if (!form.getValues('revealingPhishingEmailHtml')) {
        form.setValue(
          'revealingPhishingEmailHtml',
          setting.revealingPhishingEmailHtml
        )
      }

      if (!form.getValues('sendEmailPattern')) {
        form.setValue('sendEmailPattern', setting.sendEmailPattern)
      }

      if (isNew) {
        if (!isDeliveryContentsOnly) {
          form.setValue(
            'revealingPhishingEmailMailFromId',
            mailFroms.collection[0].uuid
          )
        }

        form.setValue(
          'phishingResultLayoutTemplate',
          setting.phishingResultLayoutTemplate
        )
        form.setValue('phishingResultTitle', setting.phishingResultTitle)
        form.setValue('phishingResultBody', setting.phishingResultBody)
        form.setValue('phishingResultSign', setting.phishingResultSign)
        form.setValue(
          'isPhishingSiteDirectAccess',
          setting.isPhishingSiteDirectAccess
        )
        form.setValue('riskUserEmailSubject', setting.riskUserEmailSubject)
        form.setValue('riskUserEmailHtml', setting.riskUserEmailHtml)
        form.setValue(
          'isDisplayLogoInLearning',
          setting.isDisplayLogoInLearning
        )
        form.setValue(
          'isDisplayLogoInResearch',
          setting.isDisplayLogoInResearch
        )
        form.setValue('isTrackMailOpen', setting.isTrackMailOpen)
        form.setValue('learningContentComment', setting.learningContentComment)
        form.setValue('learningComment', setting.learningComment)
        form.setValue('isRiskReplyUser', setting.isRiskReplyUser)
        form.setValue('isArchiveAttach', setting.isArchiveAttach)
        form.setValue(
          'isArchiveAttachPassword',
          setting.isArchiveAttachPassword
        )
        form.setValue('archiveAttachPassword', setting.archiveAttachPassword)
        form.setValue('isCustomNotifyPassword', setting.isCustomNotifyPassword)
        form.setValue(
          'notifyArchivePasswordSubject',
          setting.notifyArchivePasswordSubject
        )
        form.setValue(
          'notifyArchivePasswordHtml',
          setting.notifyArchivePasswordHtml
        )
        form.setValue('isSendLearningMail', setting.isSendLearningMail)
        form.setValue('remindEmailSubject', setting.remindEmailSubject)
        form.setValue('remindEmailHtml', setting.remindEmailHtml)
      }
    },
  })

  //Movie関連
  const moviesModal = useCsbDataModal<MoviesArray>()
  const [movie, setMovie] = useState<MoviesArray[number]>()
  const [getMovies, { loading: isLoadMovies }] =
    useMoviesWithPhishingEditFormLazyQuery({
      onCompleted: ({ movies }) => {
        moviesModal.setData(movies.collection)
      },
    })
  useMovieWithPhishingEditFormQuery({
    onCompleted: ({ movie }) => {
      setMovie(movie)
    },
    skip: !form.getValues('movieId'),
    variables: {
      uuid: form.getValues('movieId'),
    },
  })
  //現在のmovieのデータを管理するstate
  const [currentMovie, setCurrentMovie] = useState<
    MoviesArray[number] | undefined
  >(undefined)

  //Comic関連
  const comicsModal = useCsbDataModal<ComicsArray>()
  const [comic, setComic] = useState<ComicsArray[number]>()
  const [getComics, { loading: isLoadComics }] =
    useComicsWithPhishingEditFormLazyQuery({
      onCompleted: ({ comics }) => {
        comicsModal.setData(comics.collection)
      },
    })
  useComicWithPhishingEditFormQuery({
    onCompleted: ({ comic }) => {
      setComic(comic)
    },
    skip: !form.getValues('comicId'),
    variables: {
      uuid: form.getValues('comicId'),
    },
  })

  //Learn関連
  const learnsModal = useCsbDataModal<LearnsArray>()
  const [learn, setLearn] = useState<LearnsArray[number]>()
  const [getLearns, { loading: isLoadLearns }] =
    useLearnsWithPhishingEditFormLazyQuery({
      onCompleted: ({ learns }) => {
        learnsModal.setData(learns.collection)
      },
    })
  useLearnWithPhishingEditFormQuery({
    onCompleted: ({ learn }) => {
      setLearn(learn)
    },
    skip: !form.getValues('learnId'),
    variables: {
      uuid: form.getValues('learnId'),
    },
  })

  //アンケート関連
  const researchesModal = useCsbDataModal<ResearchesArray>()
  const [research, setResearch] = useState<ResearchesArray[number]>()
  const [getResearches, { loading: isLoadResearches }] =
    useResearchesWithPhishingEditFormLazyQuery({
      onCompleted: ({ researches }) => {
        // 有効期限が過ぎているものは表示しない
        const today = dayjs(new Date()).format('YYYY/MM/DD')
        const list =
          researches.collection.filter(
            (research) =>
              research.closedAt === null ||
              dayjs(research.closedAt).format('YYYY/MM/DD') >= today
          ) ?? []
        researchesModal.setData(list)
      },
    })
  useResearchWithPhishingEditFormQuery({
    onCompleted: ({ research }) => {
      setResearch(research)
    },
    skip: !form.getValues('researchId'),
    variables: {
      uuid: form.getValues('researchId'),
    },
  })

  //役職のselectを管理するhooks
  const {
    onChangeSelected: onChangePositionsSelected,
    select: positionsSelect,
  } = useMultiSelect<Pick<Position, 'name' | 'uuid'>[] | undefined>(
    data?.positions.collection,
    form.getValues('positionIds')
  )

  //組織のselectを管理するhooks
  const {
    onChangeSelected: onChangeOrganizationsSelected,
    select: organizationsSelect,
  } = useMultiSelect<Pick<Organization, 'name' | 'uuid'>[] | undefined>(
    data?.organizations.collection,
    form.getValues('organizationIds')
  )

  //グループのselectを管理するhooks
  const { onChangeSelected: onChangeGroupsSelected, select: groupsSelect } =
    useMultiSelect<Pick<Group, 'name' | 'uuid'>[] | undefined>(
      data?.groups.collection,
      form.getValues('groupIds')
    )

  const [reRender, setReRender] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  useEffect(() => {}, [reRender, form])

  const [vimeoState, setVimeoState] = useState({
    error: false,
    loading: false,
  })

  const modalClose = () => {
    onClose()
    setVimeoState({
      error: false,
      loading: true,
    })
  }

  const { company: myCompany } = useMe()

  // フィッシングサイトで会社指定があるものは、自分の会社のものか、全体のものを表示する
  const phishingSites =
    data?.phishingSites.collection.filter(
      (phishingSite) =>
        phishingSite.companies.length === 0 ||
        phishingSite.companies
          .map((c) => c.uuid)
          .includes(myCompany?.uuid ?? '')
    ) ?? []

  // 送信間隔、送信数の制御、種明かし画面タイプ
  const sendBlockIntervals = Array.from([1, 2, 3, 4, 5, 6], (x) => x * 10)
  const [sendBlockInterval, setSendBlockInterval] = useState<
    number | undefined
  >(form.getValues('sendBlockInterval'))
  const [sendMaxBlockSize, setSendMaxBlockSize] = useState<number | undefined>(
    undefined
  )

  useEffect(() => {
    // １通あたりの送信間隔を設定から取得
    // １分あたりの送信件数を計算
    const sendMailInterval = data?.setting?.sendMailInterval ?? 1.0
    const blockSizePerMinute = Math.trunc((1.0 / sendMailInterval) * 60)

    if (sendBlockInterval) {
      // 送信間隔に応じた最大送信件数を設定
      const maxBlockSize = Number(sendBlockInterval) * blockSizePerMinute
      setSendMaxBlockSize(maxBlockSize)
      if (Number(form.getValues('sendBlockSize')) > maxBlockSize) {
        form.setValue('sendBlockSize', maxBlockSize)
      }
      if (!form.getValues('sendBlockSize')) {
        form.setValue('sendBlockSize', 1)
      }
    } else {
      // 最大送信件数、送信間隔をクリア
      setSendMaxBlockSize(undefined)
      form.setValue('sendBlockSize', null)
    }

    if (!form.getValues('sendEmailPattern')) {
      // 送信パターンを設定から取得
      form.setValue(
        'sendEmailPattern',
        data?.setting.sendEmailPattern ?? SendEmailPattern.Html
      )
    }
  }, [form, sendBlockInterval, data])

  const isDefaultPhishingResultLayout =
    form.watch('phishingResultLayoutTemplate') ===
    PhishingResultLayoutTemplate.Default

  const [isDisabledReplyRiskUser, setIsDisabledReplyRiskUser] = useState(
    form.getValues('isFromAsReply') ?? false
  )

  const mailFromOptions: MailFromOption[] = useMemo(
    () => createMailFromOptions(data?.mailFroms.collection),
    [data?.mailFroms.collection]
  )

  const mailFromLabel = useMemo(
    () => getMalFromEmail(form.getValues('mailFromId'), mailFromOptions),
    [form, mailFromOptions]
  )

  const [mailFrom, setMailFrom] = useState<MailFromOption | null>({
    label: '',
    value: '',
  })

  useEffect(() => {
    if (mailFromLabel) {
      setMailFrom({
        label: mailFromLabel,
        value: form.getValues('mailFromId'),
      })
    } else {
      setMailFrom(null)
    }
  }, [form, mailFromLabel, mailFromOptions])

  return (
    <>
      <VStack align={'start'} maxW={600} spacing={5} w={'full'}>
        <CsbFormControl
          errorText={findError(errors, 'name')?.message}
          helperText={'管理用の表示名です。メール送信には使用されません。'}
          labelText={'管理用ラベル'}
        >
          <Input
            disabled={isLock}
            placeholder={'人事評価に関するアンケート'}
            {...form.register('name')}
          />
        </CsbFormControl>
        {!isDeliveryContentsOnly && (
          <Controller
            control={form.control}
            name={'rating'}
            render={({ field: { onChange, value } }) => (
              <CsbFormControl
                errorText={findError(errors, 'rating')?.message}
                labelText={
                  <HStack>
                    <Text>難易度</Text>
                    <CsbHint ml={4}>
                      <>
                        テンプレートからメールを選んだ場合、自動で難易度が設定されます。
                        <br />
                        自作のフィッシングメールの場合、任意の難易度を設定してください。
                      </>
                    </CsbHint>
                  </HStack>
                }
              >
                <Rating
                  emptySymbol={
                    <FaStar fill={'rgb(230,230,230)'} fontSize={'32px'} />
                  }
                  fractions={2}
                  fullSymbol={
                    <FaStar
                      fill={isLock ? 'gray' : 'orange'}
                      fontSize={'32px'}
                    />
                  }
                  initialRating={value ?? 3}
                  readonly={isLock}
                  onChange={(value) => {
                    onChange(value)
                  }}
                />
              </CsbFormControl>
            )}
          />
        )}
        <HStack spacing={2} w={'full'}>
          <CsbFormControl
            errorText={findError(errors, 'fromName')?.message}
            labelText={'差出人名'}
          >
            <Input {...form.register('fromName')} disabled={isLock} />
          </CsbFormControl>
          <CsbFormControl
            errorText={findError(errors, 'mailFromId')?.message}
            labelText={'送信元メールアドレス'}
          >
            {!loading && (
              <CsbMailFromSelect
                isDisabled={isLock}
                mailFrom={mailFrom}
                mailFromOptions={mailFromOptions}
                setMalFrom={(mailFrom) => {
                  setMailFrom(mailFrom)
                  form.setValue('mailFromId', mailFrom?.value ?? '')
                }}
              />
            )}
          </CsbFormControl>
        </HStack>
        {!isDeliveryContentsOnly && (
          <CsbFormControl labelText={'フィッシングサイト'}>
            {!loading && data && (
              <Select
                defaultValue={
                  form.getValues('phishingSiteId') || phishingSites.length > 0
                    ? phishingSites[0].uuid
                    : ''
                }
                disabled={isLock}
                {...form.register('phishingSiteId')}
              >
                {phishingSites.map((item, index) => (
                  <option key={`${item.uuid}_${index}`} value={item.uuid}>
                    {item.name}
                  </option>
                ))}
              </Select>
            )}
          </CsbFormControl>
        )}

        <CsbFormControl>
          <Controller
            control={form.control}
            name={'isTest'}
            render={({ field }) => (
              <Checkbox
                isChecked={form.getValues('isTest')}
                isDisabled={isLock || (company?.testMailCount ?? 0) === 0}
                ref={field.ref}
                onChange={(e) => {
                  field.onChange(e)
                }}
              >
                テストとして送信する
              </Checkbox>
            )}
          />
        </CsbFormControl>

        <HStack>
          <CsbFormControl>
            <Controller
              control={form.control}
              name={'isSendAllUsers'}
              render={({ field }) => (
                <Checkbox
                  isChecked={form.getValues('isSendAllUsers')}
                  isDisabled={isLock}
                  ref={field.ref}
                  onChange={(e) => {
                    field.onChange(e)
                  }}
                >
                  全員にメールを送信する
                </Checkbox>
              )}
            />
          </CsbFormControl>
          <CsbHint>
            <>
              送信する「組織」「役職」「グループ」を選択してください。
              <br />
              {/*複数の「組織」「役職」「グループ」に属しているユーザーには、重複せずに1通のみ訓練メールが送信されます。*/}
              選択した「組織」「役職」「グループ」に1つでも当てはまるユーザーに訓練メールが送信されます。
            </>
          </CsbHint>
        </HStack>
        <CsbFormControl
          errorText={findError(errors, 'organizationIds')?.message}
          helperText={'送信対象の組織を選択してください。'}
          isDisabled={form.watch('isSendAllUsers')}
          labelText={'組織'}
        >
          {!loading && (
            <CsbMultiSelect
              initialSelectedItems={organizationsSelect.selectedOptions}
              isDisabled={form.watch('isSendAllUsers') || isLock}
              my={0}
              options={organizationsSelect.options}
              onChangeSelected={onChangeOrganizationsSelected((uuids) =>
                form.setValue('organizationIds', uuids)
              )}
            />
          )}
        </CsbFormControl>
        <CsbFormControl
          errorText={findError(errors, 'positionIds')?.message}
          helperText={'送信対象の役職を選択してください。'}
          isDisabled={form.watch('isSendAllUsers')}
          labelText={'役職'}
        >
          {!loading && (
            <CsbMultiSelect
              initialSelectedItems={positionsSelect.selectedOptions}
              isDisabled={form.watch('isSendAllUsers') || isLock}
              my={0}
              options={positionsSelect.options}
              onChangeSelected={onChangePositionsSelected((uuids) =>
                form.setValue('positionIds', uuids)
              )}
            />
          )}
        </CsbFormControl>
        <CsbFormControl
          errorText={findError(errors, 'groups')?.message}
          helperText={'送信対象のグループを選択してください。'}
          isDisabled={form.watch('isSendAllUsers')}
          labelText={'グループ'}
        >
          {!loading && (
            <CsbMultiSelect
              initialSelectedItems={groupsSelect.selectedOptions}
              isDisabled={form.watch('isSendAllUsers') || isLock}
              my={0}
              options={groupsSelect.options}
              onChangeSelected={onChangeGroupsSelected((uuids) => {
                form.setValue('groupIds', uuids)
              })}
            />
          )}
        </CsbFormControl>
      </VStack>
      <VStack align={'start'} mt={10} spacing={5}>
        <CsbFormControl
          errorText={findError(errors, 'sendEmailPattern')?.message}
          labelText={'メールフォーマット'}
          maxW={600}
        >
          <Select
            defaultValue={Number(
              form.getValues('sendEmailPattern') || SendEmailPattern.Html
            )}
            disabled={isLock}
            {...form.register('sendEmailPattern')}
          >
            <option value={SendEmailPattern.Html}>
              メールをHTML形式で送信する
            </option>
            <option value={SendEmailPattern.Text}>
              メールをテキスト形式で送信する
            </option>
          </Select>
        </CsbFormControl>
        <CsbFormControl
          errorText={findError(errors, 'subject')?.message}
          helperText={'メールの件名を記入'}
          labelText={'件名'}
          maxW={600}
          w={'full'}
        >
          <Input
            disabled={isLock}
            placeholder={'人事評価に関するアンケート'}
            {...form.register('subject')}
          />
        </CsbFormControl>
        <Flex align={'end'} gridGap={5} maxW={600} w={'full'}>
          <CsbFormControl
            errorText={findError(errors, 'phishingLinkLabel')?.message}
            labelText={
              isDeliveryContentsOnly
                ? 'コンテンツリンクテキスト'
                : 'フィッシングリンクテキスト'
            }
          >
            <Input {...form.register('phishingLinkLabel')} disabled={isLock} />
          </CsbFormControl>
          <Button
            flexShrink={0}
            isDisabled={isLock}
            onClick={() => {
              setReRender((prevState) => !prevState)
              form.setValue('body', form.getValues('body') + '{{link}}')
            }}
          >
            リンクを挿入
          </Button>
        </Flex>
        <CsbFormControl
          errorText={findError(errors, 'body')?.message}
          labelText={
            <HStack>
              <Text>本文</Text>
              <chakra.button
                type={'button'}
                onClick={mailPlaceholderModalOnOpen}
              >
                <QuestionOutlineIcon color={'teal.500'} />
              </chakra.button>
            </HStack>
          }
          maxW={800}
          w={'full'}
        >
          <Controller
            control={form.control}
            name={'body'}
            render={({ field: { onChange, value } }) => (
              <CsbEditor
                data={value ?? ''}
                disabled={isLock || !isWritePhishingMail}
                isHtmlLocked={!!form.getValues('isBodyLock')}
                onChangeHtml={onChange}
                onHtmlLocked={(locked) => form.setValue('isBodyLock', locked)}
              />
            )}
          />
        </CsbFormControl>
        {!isDeliveryContentsOnly && (
          <AttachFileForm
            data={data}
            errors={errors}
            form={form}
            isLock={isLock}
            isWritePhishingMail={isWritePhishingMail}
          />
        )}
      </VStack>
      <VStack align={'start'} maxW={600} mt={10} spacing={5}>
        <VStack align={'start'} w={'full'}>
          <Heading fontSize={24}>学習コンテンツ</Heading>
          <Divider />
        </VStack>
        <CsbFormControl errorText={findError(errors, 'movieOrComic')?.message}>
          {movie && (
            <CsbListItem
              as={'div'}
              borderBottom={'none'}
              display={'grid'}
              gridColumnGap={3}
              gridTemplateColumns={'150px 1fr 100px 40px'}
              px={0}
            >
              <AspectRatio ratio={15 / 10}>
                <Image src={movie?.thumbnail} />
              </AspectRatio>
              <Text isTruncated fontSize={'sm'} fontWeight={'bold'}>
                {movie?.name}
              </Text>
              <Button
                colorScheme={'gray'}
                onClick={() => {
                  setCurrentMovie(movie)
                  onOpen()
                }}
              >
                プレビュー
              </Button>
              <CsbDeleteButton
                isDisabled={isLock}
                onClick={() => {
                  setMovie(undefined)
                  form.setValue('movieId', undefined)
                }}
              >
                削除
              </CsbDeleteButton>
            </CsbListItem>
          )}
          {!movie && (
            <Button
              isDisabled={isLock}
              w={'300px'}
              onClick={() => {
                getMovies()
                moviesModal.onOpen()
              }}
            >
              動画を選択する
            </Button>
          )}
        </CsbFormControl>
        <CsbFormControl errorText={findError(errors, 'movieOrComic')?.message}>
          {comic && (
            <CsbListItem
              as={'div'}
              borderBottom={'none'}
              display={'grid'}
              gridColumnGap={3}
              gridTemplateColumns={'150px 1fr 100px 40px'}
              px={0}
            >
              <AspectRatio ratio={15 / 10}>
                {comic.thumbnail && <Image src={comic.thumbnail} />}
              </AspectRatio>
              <Text isTruncated fontSize={'sm'} fontWeight={'bold'}>
                {comic.name}
              </Text>
              <a
                href={`/preview/comics/${comic.uuid}`}
                rel={'noreferrer'}
                target={'_blank'}
              >
                <Button as={'div'} colorScheme={'gray'}>
                  プレビュー
                </Button>
              </a>
              <CsbDeleteButton
                isDisabled={isLock}
                onClick={() => {
                  setComic(undefined)
                  form.setValue('comicId', undefined)
                }}
              >
                削除
              </CsbDeleteButton>
            </CsbListItem>
          )}
          {!comic && (
            <Button
              isDisabled={isLock}
              w={'300px'}
              onClick={() => {
                getComics()
                comicsModal.onOpen()
              }}
            >
              コミックを選択する
            </Button>
          )}
        </CsbFormControl>
        <CsbFormControl errorText={findError(errors, 'learn')?.message}>
          {learn && (
            <CsbListItem
              as={'div'}
              borderBottom={'none'}
              display={'grid'}
              gridColumnGap={3}
              gridTemplateColumns={'1fr 100px 40px'}
              px={0}
            >
              <Text isTruncated fontSize={'sm'} fontWeight={'bold'}>
                {learn.name}
              </Text>
              <a
                href={`/preview/learn/${learn.uuid}?isDisplayLogo=${
                  form.getValues('isDisplayLogoInLearning') ?? true
                }`}
                rel={'noreferrer'}
                target={'_blank'}
              >
                <Button as={'div'} colorScheme={'gray'}>
                  プレビュー
                </Button>
              </a>
              <CsbDeleteButton
                isDisabled={isLock}
                onClick={() => {
                  setLearn(undefined)
                  form.setValue('learnId', undefined)
                }}
              >
                削除
              </CsbDeleteButton>
            </CsbListItem>
          )}
          {!learn && (
            <Button
              isDisabled={isLock}
              w={'300px'}
              onClick={() => {
                getLearns()
                learnsModal.onOpen()
              }}
            >
              学習を選択する
            </Button>
          )}
        </CsbFormControl>

        <CsbFormControl
          errorText={findError(errors, 'originalLearningUrl')?.message}
          helperText={
            '種明かしページから特定のURLに遷移させたい場合に入力してください'
          }
          labelText={'オリジナル学習コンテンツURL'}
          maxW={600}
          w={'full'}
        >
          <Input
            disabled={isLock}
            placeholder={'https://'}
            {...form.register('originalLearningUrl')}
          />
        </CsbFormControl>
        <CsbFormControl
          errorText={findError(errors, 'pdfId')?.message}
          labelText={'学習埋め込みPDF'}
        >
          {!loading && (
            <Select
              disabled={isLock}
              {...form.register('pdfId')}
              defaultValue={form.getValues('pdfId')}
            >
              <option value={''}>未選択</option>
              {data?.pdfs.collection.map((item) => (
                <option key={item.uuid} value={item.uuid}>
                  {item.name}
                </option>
              ))}
            </Select>
          )}
        </CsbFormControl>
        {/*<CsbFormControl*/}
        {/*  errorText={findError(errors, 'pdfLink')?.message}*/}
        {/*  labelText={*/}
        {/*    <HStack>*/}
        {/*      <Text>PDF埋め込み(Docswell)</Text>*/}
        {/*    </HStack>*/}
        {/*  }*/}
        {/*>*/}
        {/*  <Controller*/}
        {/*    control={form.control}*/}
        {/*    name={'pdfLink'}*/}
        {/*    render={({ field }) => (*/}
        {/*      <Textarea resize={'vertical'} rows={10} w="100%" {...field} />*/}
        {/*    )}*/}
        {/*  />*/}
        {/*</CsbFormControl>*/}
      </VStack>

      <VStack align={'start'} maxW={600} mt={10} spacing={5}>
        <VStack align={'start'} w={'full'}>
          <Heading fontSize={24}>
            {!isDeliveryContentsOnly ? '種明かしメール' : 'リマインドメール'}
          </Heading>
          <Divider />
        </VStack>

        <RevealingPhishingForm
          errors={errors}
          form={form}
          isLock={isLock}
          isWritePhishingMail={isWritePhishingMail}
          loading={loading}
          mailFroms={data?.mailFroms.collection}
        />

        <Button
          disabled={isLock || !isWritePhishingMail}
          onClick={() => {
            form.setValue(
              'revealingPhishingEmailSubject',
              data?.setting.revealingPhishingEmailSubject ?? ''
            )
            form.setValue(
              'revealingPhishingEmailHtml',
              data?.setting.revealingPhishingEmailHtml ?? ''
            )
          }}
        >
          デフォルトに戻す
        </Button>

        {!isDeliveryContentsOnly && (
          <>
            <CsbFormControl labelText={'リスクユーザーへのメール配信'}>
              <Controller
                control={form.control}
                name={'isSendLearningMail'}
                render={({ field }) => (
                  <Checkbox
                    defaultChecked
                    isChecked={form.getValues('isSendLearningMail')}
                    isDisabled={isLock}
                    ref={field.ref}
                    onChange={field.onChange}
                  >
                    リスクユーザーへメールを配信をする
                  </Checkbox>
                )}
              />
            </CsbFormControl>
            <CsbFormControl
              helperText={
                'メール本文のリンククリックでリスクのある行動と判定します'
              }
            >
              <Controller
                control={form.control}
                name={'isPhishingSiteDirectAccess'}
                render={({ field }) => (
                  <Checkbox
                    isChecked={form.getValues('isPhishingSiteDirectAccess')}
                    isDisabled={isLock}
                    ref={field.ref}
                    onChange={field.onChange}
                  >
                    フィッシングサイトを経由しない
                  </Checkbox>
                )}
              />
            </CsbFormControl>
          </>
        )}
        <CsbFormControl
          errorText={findError(errors, 'research')?.message}
          labelText={'アンケート'}
        >
          {research && (
            <CsbListItem
              as={'div'}
              borderBottom={'none'}
              display={'grid'}
              gridColumnGap={3}
              gridTemplateColumns={'1fr 100px 40px'}
              px={0}
            >
              <Text isTruncated fontSize={'sm'} fontWeight={'bold'}>
                {research.name}
              </Text>
              <a
                href={`/preview/researches/${research.uuid}?isDisplayLogo=${
                  form.getValues('isDisplayLogoInResearch') ?? true
                }`}
                rel={'noreferrer'}
                target={'_blank'}
              >
                <Button as={'div'} colorScheme={'gray'}>
                  プレビュー
                </Button>
              </a>
              <CsbDeleteButton
                isDisabled={isLock}
                onClick={() => {
                  setResearch(undefined)
                  form.setValue('researchId', undefined)
                }}
              >
                削除
              </CsbDeleteButton>
            </CsbListItem>
          )}
          {!research && (
            <Button
              isDisabled={isLock}
              w={'300px'}
              onClick={() => {
                getResearches()
                researchesModal.onOpen()
              }}
            >
              アンケートを選択する
            </Button>
          )}
        </CsbFormControl>
      </VStack>

      {schedules && schedules.length > 0 && (
        <VStack align={'start'} maxW={700} mt={10} spacing={5}>
          <VStack align={'start'} w={'full'}>
            <Heading fontSize={24}>分割配信スケジュール</Heading>
            <Divider />
          </VStack>
          <PhishingEmailSchedules schedules={schedules} />
        </VStack>
      )}

      <Accordion allowToggle maxW={600} mt={10}>
        <AccordionItem sx={{ border: 'none' }}>
          <AccordionButton
            sx={{
              '&:hover': {
                backgroundColor: 'white',
              },
            }}
          >
            <Box as="span" flex="1" textAlign="right">
              詳細設定
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <VStack align={'start'} maxW={600} spacing={5}>
              <VStack align={'start'} w={'full'}>
                <Heading fontSize={24}>メール分割配信</Heading>
                <Divider />
              </VStack>
              <>
                <CsbFormControl
                  errorText={findError(errors, 'sendMailInterval')?.message}
                  labelText={'メール配信間隔 '}
                  mt={5}
                >
                  <Controller
                    control={form.control}
                    name={'sendBlockInterval'}
                    render={({ field: { onChange, ...rest } }) => (
                      <Select
                        {...rest}
                        onChange={(e) => {
                          onChange(e)
                          if (e.target.value === '') {
                            setSendBlockInterval(undefined)
                          } else {
                            setSendBlockInterval(Number(e.target.value))
                          }
                        }}
                      >
                        <option value={''}>未選択</option>
                        {sendBlockIntervals.map((interval, index) => (
                          <option key={`${index}`} value={interval}>
                            {interval}分
                          </option>
                        ))}
                      </Select>
                    )}
                  />
                </CsbFormControl>
                <CsbFormControl
                  display={sendBlockInterval ? 'block' : 'none'}
                  errorText={findError(errors, 'sendBlockSize')?.message}
                  labelText={'グループあたりの送信数'}
                  mt={5}
                >
                  <Flex align={'center'}>
                    <Controller
                      control={form.control}
                      name={'sendBlockSize'}
                      render={({ field: { ref, value, ...restField } }) => {
                        const rest = {
                          ...restField,
                          ...{ value: Number(value) },
                        }
                        return (
                          <NumberInput
                            disabled={isLock}
                            max={sendMaxBlockSize}
                            maxW={100}
                            min={1}
                            {...rest}
                            onChange={(value) => {
                              form.setValue('sendBlockSize', Number(value))
                            }}
                          >
                            <NumberInputField
                              name={rest.name}
                              ref={ref}
                              textAlign={'right'}
                            />
                            <NumberInputStepper>
                              <NumberIncrementStepper />
                              <NumberDecrementStepper />
                            </NumberInputStepper>
                          </NumberInput>
                        )
                      }}
                    />
                    <Text ml={2}>{`(最大: ${sendMaxBlockSize}${
                      form.getValues('isCustomNotifyPassword')
                        ? '　※パスワード通知メール含む'
                        : ''
                    }）`}</Text>
                  </Flex>
                </CsbFormControl>
              </>
            </VStack>
            <VStack align={'start'} maxW={600} mt={10} spacing={5}>
              <VStack align={'start'} w={'full'}>
                <Heading fontSize={24}>メール開封検知設定</Heading>
                <Divider />
              </VStack>

              <CsbFormControl
                errorText={findError(errors, 'isTrackMailOpen')?.message}
                labelText={'メール開封検知'}
                mt={5}
              >
                <Controller
                  control={form.control}
                  name={'isTrackMailOpen'}
                  render={({ field: { onChange, value } }) => (
                    <HStack fontSize={'sm'}>
                      <Text>開封を検知しない</Text>
                      <Switch isChecked={value ?? true} onChange={onChange} />
                      <Text>開封を検知する</Text>
                    </HStack>
                  )}
                />
              </CsbFormControl>
            </VStack>

            {!isDeliveryContentsOnly && (
              <>
                <VStack align={'start'} maxW={600} mt={10}>
                  <Box w={'full'}>
                    <VStack align={'start'}>
                      <Heading fontSize={24}>
                        リスクユーザーへのメール通知
                      </Heading>
                      <Divider />
                    </VStack>
                    <CsbFormControl
                      errorText={
                        findError(errors, 'riskUserEmailSubject')?.message
                      }
                      labelText={'メールの件名'}
                      mt={5}
                    >
                      <Controller
                        control={form.control}
                        name={'riskUserEmailSubject'}
                        render={({ field }) => (
                          <Input
                            w={'full'}
                            {...field}
                            value={field.value || ''}
                          />
                        )}
                      />
                    </CsbFormControl>
                    <CsbFormControl
                      errorText={
                        findError(errors, 'riskUserEmailHtml')?.message
                      }
                      labelText={
                        <HStack>
                          <Text>メールの本文</Text>
                          <chakra.button
                            disabled={
                              !!form.getValues('isRiskUserEmailHtmlLock')
                            }
                            type={'button'}
                            onClick={mailRevealingModalOnOpen}
                          >
                            <QuestionOutlineIcon color={'teal.500'} />
                          </chakra.button>
                        </HStack>
                      }
                      mt={5}
                    >
                      <Controller
                        control={form.control}
                        name={'riskUserEmailHtml'}
                        render={({ field: { onChange, value } }) => (
                          <CsbEditor
                            data={value ?? ''}
                            isHtmlLocked={
                              !!form.getValues('isRiskUserEmailHtmlLock')
                            }
                            onChangeHtml={onChange}
                            onHtmlLocked={(locked) =>
                              form.setValue('isRiskUserEmailHtmlLock', locked)
                            }
                          />
                        )}
                      />
                    </CsbFormControl>
                    <Button
                      disabled={isLock || !isWritePhishingMail}
                      mt={5}
                      onClick={() => {
                        form.setValue(
                          'riskUserEmailSubject',
                          data?.setting.riskUserEmailSubject ?? ''
                        )
                        form.setValue(
                          'riskUserEmailHtml',
                          data?.setting.riskUserEmailHtml ?? ''
                        )
                      }}
                    >
                      デフォルトに戻す
                    </Button>
                  </Box>
                </VStack>
                <VStack align={'start'} maxW={600} mt={10} spacing={5}>
                  <Box w={'full'}>
                    <VStack align={'start'}>
                      <Heading fontSize={24}>種明かし画面</Heading>
                      <Divider />
                    </VStack>
                    <CsbFormControl
                      errorText={
                        findError(errors, 'phishingResultLayoutTemplate')
                          ?.message
                      }
                      labelText={'レイアウトテンプレート'}
                      mt={5}
                    >
                      {!loading && (
                        <Select
                          {...form.register('phishingResultLayoutTemplate')}
                        >
                          <option value={PhishingResultLayoutTemplate.Default}>
                            デフォルト
                          </option>
                          <option value={PhishingResultLayoutTemplate.Custom}>
                            カスタム
                          </option>
                        </Select>
                      )}
                    </CsbFormControl>
                    <CsbFormControl
                      errorText={
                        findError(errors, 'phishingResultTitle')?.message
                      }
                      helperText={
                        isDefaultPhishingResultLayout
                          ? undefined
                          : 'タイトルタグとして利用されます'
                      }
                      labelText={'ページタイトル'}
                      mt={5}
                    >
                      <Controller
                        control={form.control}
                        name={'phishingResultTitle'}
                        render={({ field }) => (
                          <Textarea
                            resize={'vertical'}
                            rows={isDefaultPhishingResultLayout ? 10 : 1}
                            w="100%"
                            {...field}
                            value={field.value || ''}
                          />
                        )}
                      />
                    </CsbFormControl>
                    <CsbFormControl
                      errorText={
                        findError(errors, 'phishingResultBody')?.message
                      }
                      labelText={
                        <HStack>
                          <Text>ページ本文</Text>
                          <PhishingResultPagePlaceholderModal
                            trigger={
                              <chakra.button type={'button'}>
                                <QuestionOutlineIcon color={'teal.500'} />
                              </chakra.button>
                            }
                          />
                        </HStack>
                      }
                      mt={5}
                      w={'full'}
                    >
                      {loading &&
                      form.getValues('phishingResultBody') ? null : (
                        <Controller
                          control={form.control}
                          name={'phishingResultBody'}
                          render={({ field: { onChange, value } }) => (
                            <CsbEditor
                              data={value ?? ''}
                              isHtmlLocked={
                                !!form.getValues('isPhishingResultBodyLock')
                              }
                              onChangeHtml={onChange}
                              onHtmlLocked={(locked) =>
                                form.setValue(
                                  'isPhishingResultBodyLock',
                                  locked
                                )
                              }
                            />
                          )}
                        />
                      )}
                    </CsbFormControl>
                    {isDefaultPhishingResultLayout && (
                      <>
                        <CsbFormControl
                          errorText={
                            findError(errors, 'phishingResultSign')?.message
                          }
                          labelText={'署名'}
                          mt={5}
                        >
                          {loading &&
                          form.getValues('phishingResultSign') ? null : (
                            <Controller
                              control={form.control}
                              name={'phishingResultSign'}
                              render={({ field: { onChange, value } }) => (
                                <CsbEditor
                                  data={value ?? ''}
                                  isHtmlLocked={
                                    !!form.getValues('isPhishingResultSignLock')
                                  }
                                  onChangeHtml={onChange}
                                  onHtmlLocked={(locked) =>
                                    form.setValue(
                                      'isPhishingResultSignLock',
                                      locked
                                    )
                                  }
                                />
                              )}
                            />
                          )}
                        </CsbFormControl>
                        <CsbFormControl
                          errorText={findError(errors, 'linkText')?.message}
                          helperText={
                            '種明かしページのボタンテキストを上書きします'
                          }
                          labelText={'種明かし画面ボタンテキスト'}
                          maxW={600}
                          mt={5}
                          w={'full'}
                        >
                          <Input
                            {...form.register('linkText')}
                            disabled={isLock}
                          />
                        </CsbFormControl>
                      </>
                    )}
                    <Button
                      disabled={isLock || !isWritePhishingMail}
                      mt={5}
                      onClick={() => {
                        form.setValue(
                          'phishingResultLayoutTemplate',
                          data?.setting.phishingResultLayoutTemplate ?? ''
                        )
                        form.setValue(
                          'phishingResultTitle',
                          data?.setting.phishingResultTitle ?? ''
                        )
                        form.setValue(
                          'phishingResultBody',
                          data?.setting.phishingResultBody ?? ''
                        )
                        form.setValue('linkText', data?.setting.linkText ?? '')
                        form.setValue(
                          'phishingResultSign',
                          data?.setting.phishingResultSign ?? ''
                        )
                      }}
                    >
                      デフォルトに戻す
                    </Button>
                  </Box>
                </VStack>
              </>
            )}

            <VStack align={'start'} maxW={600} mt={10} spacing={5}>
              <Box w={'full'}>
                <VStack align={'start'}>
                  <Heading fontSize={24}>リマインド（繰り返し）メール</Heading>
                  <Divider />
                </VStack>
                <CsbFormControl
                  errorText={findError(errors, 'remindEmailSubject')?.message}
                  labelText={'メールの件名'}
                  mt={5}
                >
                  <Controller
                    control={form.control}
                    name={'remindEmailSubject'}
                    render={({ field }) => (
                      <Input w={'full'} {...field} value={field.value || ''} />
                    )}
                  />
                </CsbFormControl>
                <CsbFormControl
                  errorText={findError(errors, 'remindEmailHtml')?.message}
                  labelText={
                    <HStack>
                      <Text>メールの本文</Text>
                      <chakra.button
                        disabled={!!form.getValues('isRemindEmailHtmlLock')}
                        type={'button'}
                        onClick={mailRevealingModalOnOpen}
                      >
                        <QuestionOutlineIcon color={'teal.500'} />
                      </chakra.button>
                    </HStack>
                  }
                  mt={5}
                >
                  <Controller
                    control={form.control}
                    name={'remindEmailHtml'}
                    render={({ field: { onChange, value } }) => (
                      <CsbEditor
                        data={value ?? ''}
                        isHtmlLocked={!!form.getValues('isRemindEmailHtmlLock')}
                        onChangeHtml={onChange}
                        onHtmlLocked={(locked) =>
                          form.setValue('isRemindEmailHtmlLock', locked)
                        }
                      />
                    )}
                  />
                </CsbFormControl>
                <Button
                  disabled={isLock || !isWritePhishingMail}
                  mt={5}
                  onClick={() => {
                    form.setValue(
                      'remindEmailSubject',
                      data?.setting.remindEmailSubject
                    )
                    form.setValue(
                      'remindEmailHtml',
                      data?.setting.remindEmailHtml
                    )
                  }}
                >
                  デフォルトに戻す
                </Button>
              </Box>
            </VStack>

            <VStack align={'start'} maxW={600} mt={10} spacing={5}>
              <VStack align={'start'} w={'full'}>
                <Heading fontSize={24}>学習コンテンツ設定</Heading>
                <Divider />
              </VStack>
              <CsbFormControl
                errorText={findError(errors, 'learningContentComment')?.message}
                labelText={'学習コンテンツ・コメント'}
                mt={5}
              >
                {loading && form.getValues('learningContentComment') ? null : (
                  <Controller
                    control={form.control}
                    name={'learningContentComment'}
                    render={({ field: { onChange, value } }) => (
                      <CsbEditor
                        data={value ?? ''}
                        isHtmlLocked={
                          !!form.getValues('isLearningContentCommentLock')
                        }
                        onChangeHtml={onChange}
                        onHtmlLocked={(locked) =>
                          form.setValue('isLearningContentCommentLock', locked)
                        }
                      />
                    )}
                  />
                )}
              </CsbFormControl>

              <CsbFormControl
                errorText={findError(errors, 'learningComment')?.message}
                labelText={'学習・コメント'}
                mt={5}
              >
                {loading && form.getValues('learningComment') ? null : (
                  <Controller
                    control={form.control}
                    name={'learningComment'}
                    render={({ field: { onChange, value } }) => (
                      <CsbEditor
                        data={value ?? ''}
                        isHtmlLocked={!!form.getValues('isLearningCommentLock')}
                        onChangeHtml={onChange}
                        onHtmlLocked={(locked) =>
                          form.setValue('isLearningCommentLock', locked)
                        }
                      />
                    )}
                  />
                )}
              </CsbFormControl>
            </VStack>

            <VStack align={'start'} maxW={600} mt={10} spacing={5}>
              <VStack align={'start'} w={'full'}>
                <Heading fontSize={24}>ロゴ表示設定</Heading>
                <Divider />
              </VStack>

              <CsbFormControl
                errorText={
                  findError(errors, 'isDisplayLogoInLearning')?.message
                }
                labelText={'学習ページ'}
                mt={5}
              >
                <Controller
                  control={form.control}
                  name={'isDisplayLogoInLearning'}
                  render={({ field: { onChange, value } }) => (
                    <HStack fontSize={'sm'}>
                      <Text>表示しない</Text>
                      <Switch isChecked={value ?? true} onChange={onChange} />
                      <Text>表示する</Text>
                    </HStack>
                  )}
                />
              </CsbFormControl>
              <CsbFormControl
                errorText={
                  findError(errors, 'isDisplayLogoInResearch')?.message
                }
                labelText={'アンケート回答画面'}
                mt={5}
              >
                <Controller
                  control={form.control}
                  name={'isDisplayLogoInResearch'}
                  render={({ field: { onChange, value } }) => (
                    <HStack fontSize={'sm'}>
                      <Text>表示しない</Text>
                      <Switch isChecked={value ?? true} onChange={onChange} />
                      <Text>表示する</Text>
                    </HStack>
                  )}
                />
              </CsbFormControl>
            </VStack>

            <VStack align={'start'} maxW={600} mt={10} spacing={5}>
              <VStack align={'start'} w={'full'}>
                <Heading fontSize={24}>メール返信設定</Heading>
                <Divider />
              </VStack>
              <CsbFormControl
                errorText={findError(errors, 'isFromAsReply')?.message}
                labelText={'返信先を送信元メールアドレスにする'}
                mt={5}
              >
                <Controller
                  control={form.control}
                  name={'isFromAsReply'}
                  render={({ field: { onChange, value } }) => (
                    <HStack fontSize={'sm'}>
                      <Text>OFF</Text>
                      <Switch
                        isChecked={value ?? true}
                        onChange={(e) => {
                          onChange(e)
                          form.setValue('isRiskReplyUser', false)
                          setIsDisabledReplyRiskUser(e.target.checked)
                        }}
                      />
                      <Text>ON</Text>
                    </HStack>
                  )}
                />
              </CsbFormControl>
              {!isDeliveryContentsOnly && (
                <CsbFormControl
                  errorText={findError(errors, 'isRiskReplyUser')?.message}
                  labelText={'メール返信ユーザーをリスクユーザーにする'}
                  mt={5}
                >
                  <Controller
                    control={form.control}
                    name={'isRiskReplyUser'}
                    render={({ field: { onChange, value } }) => (
                      <HStack fontSize={'sm'}>
                        <Text>OFF</Text>
                        <Switch
                          isChecked={value ?? true}
                          isDisabled={isDisabledReplyRiskUser}
                          onChange={onChange}
                        />
                        <Text>ON</Text>
                      </HStack>
                    )}
                  />
                </CsbFormControl>
              )}
            </VStack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      <CsbDataModal size={'2xl'} {...moviesModal}>
        <CsbModalHeader>動画一覧</CsbModalHeader>
        <CsbModalBody mt={'18px'}>
          <Box mt={'38px'}>
            <Grid
              bg={'gray.50'}
              gridColumnGap={3}
              gridTemplateColumns={'150px 1fr 100px 100px'}
            >
              <Text
                alignItems={'center'}
                display={'flex'}
                fontSize={'xs'}
                fontWeight={'bold'}
                p={3}
              >
                サムネイル
              </Text>
              <Text
                alignItems={'center'}
                display={'flex'}
                fontSize={'xs'}
                fontWeight={'bold'}
                p={3}
              >
                タイトル
              </Text>
            </Grid>
            <CsbList
              border={'none'}
              maxH={'300px'}
              overflowY={'auto'}
              p={0}
              rounded={'none'}
              shadow={'none'}
            >
              {isLoadMovies ? (
                <>loading</>
              ) : (
                moviesModal.data?.map((item) => (
                  <CsbListItem
                    display={'grid'}
                    gridColumnGap={3}
                    gridTemplateColumns={'150px 1fr 100px 100px'}
                    key={`move_${item.uuid}`}
                    px={0}
                  >
                    <AspectRatio ratio={15 / 10}>
                      <Image src={item.thumbnail} />
                    </AspectRatio>
                    <Text isTruncated fontSize={'sm'} fontWeight={'bold'}>
                      {item.name}
                    </Text>
                    <Button
                      colorScheme={'gray'}
                      onClick={() => {
                        setCurrentMovie(item)
                        onOpen()
                      }}
                    >
                      プレビュー
                    </Button>
                    <Button
                      onClick={() => {
                        setMovie(item)
                        form.setValue('movieId', item.uuid)
                        moviesModal.onClose()
                      }}
                    >
                      反映する
                    </Button>
                  </CsbListItem>
                ))
              )}
            </CsbList>
          </Box>
        </CsbModalBody>
        <CsbModalFooter mt={7}>
          <Button colorScheme={'gray'} onClick={moviesModal.onClose}>
            キャンセル
          </Button>
        </CsbModalFooter>
      </CsbDataModal>
      <CsbDataModal size={'2xl'} {...comicsModal}>
        <CsbModalHeader>コミック一覧</CsbModalHeader>
        <CsbModalBody mt={'18px'}>
          <Box mt={'38px'}>
            <Grid
              bg={'gray.50'}
              gridColumnGap={3}
              gridTemplateColumns={'150px 1fr 100px 100px'}
            >
              <Text
                alignItems={'center'}
                display={'flex'}
                fontSize={'xs'}
                fontWeight={'bold'}
                p={3}
              >
                サムネイル
              </Text>
              <Text
                alignItems={'center'}
                display={'flex'}
                fontSize={'xs'}
                fontWeight={'bold'}
                p={3}
              >
                タイトル
              </Text>
            </Grid>
            <CsbList
              border={'none'}
              maxH={'300px'}
              overflowY={'auto'}
              p={0}
              rounded={'none'}
              shadow={'none'}
            >
              {isLoadComics ? (
                <>loading</>
              ) : (
                comicsModal.data?.map((item) => (
                  <CsbListItem
                    display={'grid'}
                    gridColumnGap={3}
                    gridTemplateColumns={'150px 1fr 100px 100px'}
                    key={`move_${item.uuid}`}
                    px={0}
                  >
                    <AspectRatio ratio={15 / 10}>
                      {item.thumbnail && <Image src={item.thumbnail} />}
                    </AspectRatio>
                    <Text isTruncated fontSize={'sm'} fontWeight={'bold'}>
                      {item.name}
                    </Text>
                    <a
                      href={`/preview/comics/${item.uuid}`}
                      rel={'noreferrer'}
                      target={'_blank'}
                    >
                      <Button as={'div'} colorScheme={'gray'}>
                        プレビュー
                      </Button>
                    </a>
                    <Button
                      onClick={() => {
                        setComic(item)
                        form.setValue('comicId', item.uuid)
                        comicsModal.onClose()
                      }}
                    >
                      反映する
                    </Button>
                  </CsbListItem>
                ))
              )}
            </CsbList>
          </Box>
        </CsbModalBody>
        <CsbModalFooter mt={7}>
          <Button colorScheme={'gray'} onClick={comicsModal.onClose}>
            キャンセル
          </Button>
        </CsbModalFooter>
      </CsbDataModal>
      <CsbDataModal size={'2xl'} {...learnsModal}>
        <CsbModalHeader>学習コンテンツ一覧</CsbModalHeader>
        <CsbModalBody mt={'18px'}>
          <Box mt={'38px'}>
            <Grid
              bg={'gray.50'}
              gridColumnGap={3}
              gridTemplateColumns={'1fr 100px 100px'}
            >
              <Text
                alignItems={'center'}
                display={'flex'}
                fontSize={'xs'}
                fontWeight={'bold'}
                p={3}
              >
                タイトル
              </Text>
            </Grid>
            <CsbList
              border={'none'}
              maxH={'300px'}
              overflowY={'auto'}
              p={0}
              rounded={'none'}
              shadow={'none'}
            >
              {isLoadLearns ? (
                <>loading</>
              ) : (
                learnsModal.data?.map((item) => (
                  <CsbListItem
                    display={'grid'}
                    gridColumnGap={3}
                    gridTemplateColumns={'1fr 100px 100px'}
                    key={`move_${item.uuid}`}
                    px={0}
                  >
                    <Text isTruncated fontSize={'sm'} fontWeight={'bold'}>
                      {item.name}
                    </Text>
                    <a
                      href={`/preview/learn/${item.uuid}?isDisplayLogo=${
                        form.getValues('isDisplayLogoInLearning') ?? true
                      }`}
                      rel={'noreferrer'}
                      target={'_blank'}
                    >
                      <Button as={'div'} colorScheme={'gray'}>
                        プレビュー
                      </Button>
                    </a>
                    <Button
                      onClick={() => {
                        setLearn(item)
                        form.setValue('learnId', item.uuid)
                        learnsModal.onClose()
                      }}
                    >
                      反映する
                    </Button>
                  </CsbListItem>
                ))
              )}
            </CsbList>
          </Box>
        </CsbModalBody>
        <CsbModalFooter mt={7}>
          <Button colorScheme={'gray'} onClick={learnsModal.onClose}>
            キャンセル
          </Button>
        </CsbModalFooter>
      </CsbDataModal>
      <CsbModal isOpen={isOpen} size={'3xl'} onClose={modalClose}>
        <CsbModalHeader>{currentMovie?.name}</CsbModalHeader>
        <ModalCloseButton />
        <CsbModalBody mt={5}>
          <AspectRatio maxW={640} mx={'auto'} ratio={16 / 9} w={'full'}>
            <>
              <Box h={4} w={4}>
                <Spinner
                  color="blue.500"
                  emptyColor="gray.200"
                  size="xl"
                  speed="0.65s"
                  thickness="4px"
                />
              </Box>
              <Vimeo
                speed={true}
                video={currentMovie?.url ?? ''}
                width={640}
                onError={(error) =>
                  setVimeoState((prevState) => ({
                    ...prevState,
                    error: !!error,
                  }))
                }
                onReady={() =>
                  setVimeoState((prevState) => ({
                    ...prevState,
                    loading: false,
                  }))
                }
              />
              {vimeoState.error && (
                <Alert status="error">
                  <AlertIcon />
                  プレビューを表示できません。
                  <br />
                  vimeoの閲覧権限を確認してください
                </Alert>
              )}
            </>
          </AspectRatio>
        </CsbModalBody>
        <CsbModalFooter mt={6}>
          <Button colorScheme={'teal'} onClick={modalClose}>
            閉じる
          </Button>
        </CsbModalFooter>
      </CsbModal>
      <CsbDataModal size={'2xl'} {...researchesModal}>
        <CsbModalHeader>アンケート一覧</CsbModalHeader>
        <CsbModalBody mt={'18px'}>
          <Box mt={'38px'}>
            <Grid
              bg={'gray.50'}
              gridColumnGap={3}
              gridTemplateColumns={'1fr 100px 100px'}
            >
              <Text
                alignItems={'center'}
                display={'flex'}
                fontSize={'xs'}
                fontWeight={'bold'}
                p={3}
              >
                タイトル
              </Text>
            </Grid>
            <CsbList
              border={'none'}
              maxH={'300px'}
              overflowY={'auto'}
              p={0}
              rounded={'none'}
              shadow={'none'}
            >
              {isLoadResearches ? (
                <>loading</>
              ) : (
                researchesModal.data?.map((item) => (
                  <CsbListItem
                    display={'grid'}
                    gridColumnGap={3}
                    gridTemplateColumns={'1fr 100px 100px'}
                    key={`move_${item.uuid}`}
                    px={0}
                  >
                    <Text isTruncated fontSize={'sm'} fontWeight={'bold'}>
                      {item.name}
                    </Text>
                    <a
                      href={`/preview/researches/${item.uuid}?isDisplayLogo=${
                        form.getValues('isDisplayLogoInResearch') ?? true
                      }`}
                      rel={'noreferrer'}
                      target={'_blank'}
                    >
                      <Button as={'div'} colorScheme={'gray'}>
                        プレビュー
                      </Button>
                    </a>
                    <Button
                      onClick={() => {
                        setResearch(item)
                        form.setValue('researchId', item.uuid)
                        researchesModal.onClose()
                      }}
                    >
                      反映する
                    </Button>
                  </CsbListItem>
                ))
              )}
            </CsbList>
          </Box>
        </CsbModalBody>
        <CsbModalFooter mt={7}>
          <Button colorScheme={'gray'} onClick={researchesModal.onClose}>
            キャンセル
          </Button>
        </CsbModalFooter>
      </CsbDataModal>

      <MailPlaceholderModal />
      <MailRevealingModal />
    </>
  )
}
