//学習のサンクスページで使うhooksのまとめ

import { useBoolean } from '@chakra-ui/react'
import {
  useAnswerResearchesQuery,
  useLearningQuery,
} from '@src/graphql/generated/graphql'
import { useLearningResult } from '@src/models/Learning'
import { useAnswerResearch, useResearchQuestions } from '@src/models/Research'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { BooleanParam, useQueryParams, withDefault } from 'use-query-params'

export const useLearningThanksHooks = () => {
  //queryからidを抽出して返す
  const { id = '' } = useParams<{ id: string }>()
  const [query] = useQueryParams({
    isHideAnswerResult: withDefault(BooleanParam, false),
  })
  const isHideAnswerResult = query.isHideAnswerResult
  const learningResult = useLearningResult({
    variables: {
      shortPath: id,
    },
  })
  const learning = useLearningQuery({
    variables: {
      shortPath: id,
    },
  })
  const researchQuestions = useResearchQuestions({
    variables: {
      shortPath: id,
    },
  })
  const answerResearches = useAnswerResearchesQuery({
    variables: {
      shortPath: id,
    },
  })

  const answerResearch = useAnswerResearch()
  const hasResearch = researchQuestions.data?.researchQuestions.length !== 0

  const isResearchLoading =
    researchQuestions.loading && answerResearches.loading

  const [isSend, { on: setIsSend }] = useBoolean(false)

  const getAnswers = (answers: string[] | null | undefined) => answers ?? []
  useEffect(() => {
    if (isResearchLoading) return

    if (answerResearch.form.formState.isSubmitting) return
    if (answerResearch.form.formState.isDirty) return

    const answerResearchesMap = new Map(
      answerResearches.data?.answerResearches.map((d) => [
        d.researchQuestionUuid,
        d.answers,
      ])
    )

    researchQuestions.data?.researchQuestions.forEach(
      (researchQuestion, index) => {
        const data = getAnswers(answerResearchesMap.get(researchQuestion.uuid))
        if (researchQuestion.answerType === 'checkbox') {
          answerResearch.form.setValue(`answers.${index}`, data)
        } else {
          answerResearch.form.setValue(
            `answers.${index}.0`,
            data.length ? data[0] : ''
          )
        }
      }
    )

    const isAnsweredResearches =
      (answerResearches.data?.answerResearches?.length ?? 0) > 0

    if (isAnsweredResearches) {
      setIsSend()
    }
  }, [
    isResearchLoading,
    researchQuestions,
    answerResearch,
    answerResearches.data?.answerResearches,
    answerResearch.form.formState,
    setIsSend,
  ])

  return {
    answerResearch,
    answerResearches,
    hasResearch,
    id,
    isHideAnswerResult,
    isResearchLoading,
    isSend,
    learning,
    learningResult,
    researchQuestions,
    setIsSend,
  }
}

export type useLearningThanksHooksResult = ReturnType<
  typeof useLearningThanksHooks
>
