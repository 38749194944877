import { useDisclosure, useToast } from '@chakra-ui/react'
import { useTemplateModal } from '@src/components/Modal/CsbTemplateModal/hooks'
import { usePhishingEmailGroups } from '@src/components/pages/phishing/hooks/usePhishingEmailGroups'
import {
  CreatePhishingEmailInput,
  CreatePhishingEmailMutationResult,
  useCreatePhishingEmailMutation,
  usePhishingEmailQuery,
  useSettingsQuery,
} from '@src/graphql/generated/graphql'
import { useErrors, useMe, usePagination, useQueryParams } from '@src/hooks'
import { useDeliveryContents } from '@src/hooks/useDeliveryContents'
import {
  phishingDeliveryContentsOnlyFormDefaultValues,
  phishingFormDefaultValues,
} from '@src/pages/phishing/formDefaultValues'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

//フィッシング作成で使うhooksのまとめ
export const usePhishingNewHooks = () => {
  const { isDeliveryContentsOnly, resolveDeliveryContentsUrl } =
    useDeliveryContents()
  //保存と保存して送信のボタンのloadingアニメーションを分岐するstate
  const [isDraftButton, setIsDraftButton] = useState(true)
  //保存して送信するModalを管理するstate
  const { isOpen, onClose, onOpen } = useDisclosure()
  const navigate = useNavigate()
  const { onBackListPage } = usePagination()

  const toast = useToast()
  const form = useForm<CreatePhishingEmailInput>({
    defaultValues: isDeliveryContentsOnly
      ? phishingDeliveryContentsOnlyFormDefaultValues
      : phishingFormDefaultValues,
  })
  const templateModal = useTemplateModal(form)
  const { reuseId } = useQueryParams()
  const isNew = !reuseId

  const { role } = useMe()
  const isWritePhishingMail = !!role?.writePhishingMail

  //フィッシング作成後に返却されるuserを管理するstate
  const [userData, setUserData] = useState<
    | NonNullable<
        NonNullable<
          CreatePhishingEmailMutationResult['data']
        >['createPhishingEmail']
      >['phishingEmail']
    | undefined
  >(undefined)

  const [errors, setErrors] = useErrors()
  const [uuid, setUuid] = useState('')

  const { groups, isGroupsDataLoading, updatePhishingEmailGroups } =
    usePhishingEmailGroups(uuid)

  //フィッシングを作成する関数
  const [createPhishingEmailMutation, { loading: isCreating }] =
    useCreatePhishingEmailMutation({
      onCompleted: ({ createPhishingEmail }) => {
        setUserData(createPhishingEmail?.phishingEmail)
        setUuid(createPhishingEmail?.phishingEmail.uuid ?? '')
        updatePhishingEmailGroups()
      },
    })

  const [isCompletedData, setIsCompleted] = useState(false)

  useEffect(() => {
    if (reuseId) return
    setIsCompleted(true)
  }, [reuseId])

  const { data: settingData } = useSettingsQuery()

  useEffect(() => {
    if (!form.getValues('sendEmailPattern')) {
      form.setValue(
        'sendEmailPattern',
        settingData?.setting.sendEmailPattern ?? 1
      )
    }
  }, [form, settingData])

  //現在のページのフィッシングのデータ
  usePhishingEmailQuery({
    onCompleted: async ({ phishingEmail }) => {
      form.setValue('researchId', phishingEmail.research?.uuid)
      form.setValue('attachId', phishingEmail.attach?.uuid)
      form.setValue('companyAttachId', phishingEmail.companyAttach?.uuid)
      form.setValue(
        'isPhishingSiteDirectAccess',
        !!phishingEmail.isPhishingSiteDirectAccess
      )
      form.setValue('isSendAllUsers', !!phishingEmail.isSendAllUsers)
      form.setValue('reserveAt', phishingEmail.reserveAt)
      form.setValue('name', phishingEmail.name)
      form.setValue('subject', phishingEmail.subject)
      form.setValue('body', phishingEmail.body)
      form.setValue('isBodyLock', !!phishingEmail.isBodyLock)
      form.setValue('fromName', phishingEmail.fromName)
      form.setValue('mailFromId', phishingEmail.mailFrom.uuid)
      form.setValue('isSendLearningMail', !!phishingEmail.isSendLearningMail)
      form.setValue(
        'organizationIds',
        phishingEmail.organizations.map(({ uuid }) => uuid)
      )
      form.setValue(
        'groupIds',
        phishingEmail.groups.map(({ uuid }) => uuid)
      )
      form.setValue(
        'positionIds',
        phishingEmail.positions.map(({ uuid }) => uuid)
      )
      form.setValue('learnId', phishingEmail.learn?.uuid)
      form.setValue('movieId', phishingEmail.movie?.uuid)
      form.setValue('comicId', phishingEmail.comic?.uuid)
      form.setValue('pdfId', phishingEmail.pdf?.uuid)
      form.setValue('phishingLinkLabel', phishingEmail.phishingLinkLabel)
      form.setValue(
        'revealingPhishingEmailSubject',
        phishingEmail.revealingPhishingEmailSubject
      )
      form.setValue(
        'revealingPhishingEmailHtml',
        phishingEmail.revealingPhishingEmailHtml
      )
      form.setValue(
        'isRevealingPhishingEmailHtmlLock',
        !!phishingEmail.isRevealingPhishingEmailHtmlLock
      )
      form.setValue(
        'revealingPhishingEmailFromName',
        phishingEmail.revealingPhishingEmailFromName
      )
      form.setValue(
        'revealingPhishingEmailFromEmail',
        phishingEmail.revealingPhishingEmailFromEmail
      )
      form.setValue(
        'revealingPhishingEmailMailFromId',
        phishingEmail.revealingPhishingEmailMailFrom?.uuid
      )
      form.setValue(
        'phishingResultLayoutTemplate',
        phishingEmail.phishingResultLayoutTemplate
      )
      form.setValue('phishingResultTitle', phishingEmail.phishingResultTitle)
      form.setValue('phishingResultBody', phishingEmail.phishingResultBody)
      form.setValue(
        'isPhishingResultBodyLock',
        !!phishingEmail.isPhishingResultBodyLock
      )
      form.setValue('phishingResultSign', phishingEmail.phishingResultSign)
      form.setValue(
        'isPhishingResultSignLock',
        !!phishingEmail.isPhishingResultSignLock
      )
      form.setValue('riskUserEmailSubject', phishingEmail.riskUserEmailSubject)
      form.setValue('riskUserEmailHtml', phishingEmail.riskUserEmailHtml)
      form.setValue(
        'isRiskUserEmailHtmlLock',
        !!phishingEmail.isRiskUserEmailHtmlLock
      )
      form.setValue('phishingSiteId', phishingEmail.phishingSite?.uuid)
      form.setValue('rating', Number(phishingEmail.rating))
      form.setValue('pdfLink', phishingEmail.pdfLink)
      form.setValue('linkText', phishingEmail.linkText)
      form.setValue(
        'isDeliveryContentsOnly',
        !!phishingEmail.isPhishingSiteDirectAccess
      )
      form.setValue('attachName', phishingEmail.attachName ?? '')
      form.setValue(
        'originalLearningUrl',
        phishingEmail.originalLearningUrl ?? ''
      )
      form.setValue(
        'sendEmailPattern',
        phishingEmail.sendEmailPattern ??
          settingData?.setting.sendEmailPattern ??
          1
      )
      form.setValue('sendBlockInterval', phishingEmail.sendBlockInterval)
      form.setValue('sendBlockSize', phishingEmail.sendBlockSize)
      form.setValue('sendEmailPattern', phishingEmail.sendEmailPattern ?? 1)

      form.setValue(
        'isDisplayLogoInLearning',
        phishingEmail.isDisplayLogoInLearning ??
          settingData?.setting.isDisplayLogoInLearning ??
          true
      )
      form.setValue(
        'isDisplayLogoInResearch',
        phishingEmail.isDisplayLogoInResearch ??
          settingData?.setting.isDisplayLogoInResearch ??
          true
      )
      form.setValue('isTrackMailOpen', phishingEmail.isTrackMailOpen)
      form.setValue(
        'learningContentComment',
        phishingEmail.learningContentComment
      )
      form.setValue(
        'isLearningContentCommentLock',
        !!phishingEmail.isLearningContentCommentLock
      )
      form.setValue('learningComment', phishingEmail.learningComment)
      form.setValue(
        'isLearningCommentLock',
        !!phishingEmail.isLearningCommentLock
      )
      form.setValue('isRiskReplyUser', phishingEmail.isRiskReplyUser)
      form.setValue('isFromAsReply', phishingEmail.isFromAsReply)
      form.setValue('isArchiveAttach', phishingEmail.isArchiveAttach)
      form.setValue('archiveAttachName', phishingEmail.archiveAttachName)
      form.setValue(
        'isArchiveAttachPassword',
        phishingEmail.isArchiveAttachPassword
      )
      form.setValue(
        'isCustomNotifyPassword',
        phishingEmail.isCustomNotifyPassword
      )
      form.setValue(
        'archiveAttachPassword',
        phishingEmail.archiveAttachPassword
      )
      form.setValue(
        'notifyArchivePasswordSubject',
        phishingEmail.notifyArchivePasswordSubject
      )
      form.setValue(
        'notifyArchivePasswordHtml',
        phishingEmail.notifyArchivePasswordHtml
      )
      form.setValue(
        'isNotifyArchivePasswordHtmlLock',
        !!phishingEmail.isNotifyArchivePasswordHtmlLock
      )
      form.setValue('isTest', phishingEmail.isTest)
      form.setValue('remindEmailSubject', phishingEmail.remindEmailSubject)
      form.setValue('remindEmailHtml', phishingEmail.remindEmailHtml)
      form.setValue(
        'isRemindEmailHtmlLock',
        !!phishingEmail.isRemindEmailHtmlLock
      )

      setIsCompleted(true)
    },
    onError: () => {
      setIsCompleted(true)
    },
    skip: !reuseId,
    variables: {
      isDeliveryContentsOnly: isDeliveryContentsOnly,
      uuid: reuseId,
    },
  })

  const onSubmit = (draft: boolean) =>
    form.handleSubmit(
      async ({
        attachId,
        attachName,
        companyAttachId,
        pdfId,
        researchId,
        reserveAt,
        sendBlockInterval,
        sendEmailPattern,
        ...inputData
      }: CreatePhishingEmailInput) => {
        // const currentTime = dayjs
        //   .utc(new Date())
        //   .local()
        //   .format('YYYY-MM-DDTHH:mm:ss')
        //保存ボタンか保存して送信するボタンかどうか
        setIsDraftButton(draft)
        try {
          await createPhishingEmailMutation({
            variables: {
              input: {
                ...inputData,
                attachId: attachId === '' ? null : attachId,
                attachName: attachName === '' ? null : attachName,
                companyAttachId:
                  companyAttachId === '' ? null : companyAttachId,
                draft: true,
                isDeliveryContentsOnly,
                pdfId: pdfId === '' ? null : pdfId,
                researchId: researchId === '' ? null : researchId,
                reserveAt,
                sendBlockInterval: !sendBlockInterval
                  ? null
                  : Number(sendBlockInterval),
                sendEmailPattern: Number(sendEmailPattern) || 1,
              },
            },
          })
          setErrors([])
          //保存ボタンの場合のみToastを発行しページ遷移する
          if (draft) {
            toast({
              duration: 3000,
              isClosable: true,
              position: 'top',
              status: 'success',
              title: 'メールを作成しました。',
            })
            navigate(resolveDeliveryContentsUrl('/phishing'))
          } else {
            //createが終わったらuserのModalが開けるようにする
            onOpen()
          }
          /**
           * TODO anyを消す
           * e instanceOf ApolloError
           * setErrorsを変更する
           */
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          toast({
            duration: 3000,
            isClosable: true,
            position: 'top',
            status: 'error',
            title: 'メールの作成に失敗しました。',
          })
          setErrors(e.graphQLErrors)
        }
      }
    )
  return {
    errors,
    form,
    groups,
    isCompletedData,
    isCreating,
    isDeliveryContentsOnly,
    isDraftButton,
    isGroupsDataLoading,
    isNew,
    isOpen,
    isWritePhishingMail,
    onBackListPage,
    onClose,
    onSubmit,
    resolveDeliveryContentsUrl,
    templateModal,
    userData,
    uuid,
  }
}

export type usePhishingNewHooksResult = ReturnType<typeof usePhishingNewHooks>
